import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table"; // custom table, or just use antd Table
import { Alert, Card, Select, Skeleton, message } from "antd";
import type { ColumnsType } from "antd/es/table";
// CostSavingsCard.tsx
import { FC, useEffect, useReducer } from "react";

interface CostDataItem {
	savings: number;
	count: number;
}

interface CostData {
	[category: string]: CostDataItem;
	total: { savings: number };
	cost: number;
}

interface CostSavingsCardProps {
	monthOptions: string[];
	costData: CostData | null;
	activeMonth: string;
	onMonthChange: (value: string) => void;
	loading?: boolean;
	error?: string;
}

interface State {
	isAnimated: boolean;
	isLoadingData: boolean;
	error: string | null;
	hasFetchedData: boolean;
}

type Action =
	| { type: "ANIMATE" }
	| { type: "SET_LOADING"; payload: boolean }
	| { type: "SET_ERROR"; payload: string | null }
	| { type: "SET_FETCHED_DATA"; payload: boolean };

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "ANIMATE":
			return { ...state, isAnimated: true };
		case "SET_LOADING":
			return { ...state, isLoadingData: action.payload };
		case "SET_ERROR":
			return { ...state, error: action.payload };
		case "SET_FETCHED_DATA":
			return { ...state, hasFetchedData: action.payload };
		default:
			return state;
	}
}

const categoriesList = [
	{ key: "ceiling", label: "Ceiling" },
	{ key: "door", label: "Door" },
	{ key: "flooring", label: "Flooring" },
	{ key: "lighting", label: "Lighting" },
	{ key: "millwork", label: "Millwork" },
	{ key: "plumbing", label: "Plumbing" },
	{ key: "wallPaint", label: "Wall Paint" },
];

export const CostSavingsCard: FC<CostSavingsCardProps> = ({
	monthOptions,
	costData,
	activeMonth,
	onMonthChange,
	loading = false,
	error,
}) => {
	const [state, dispatch] = useReducer(reducer, {
		isAnimated: false,
		isLoadingData: loading,
		error: error || null,
		hasFetchedData: false,
	});

	useEffect(() => {
		dispatch({ type: "ANIMATE" });
	}, []);

	useEffect(() => {
		dispatch({ type: "SET_LOADING", payload: loading });
	}, [loading]);

	useEffect(() => {
		if (error) {
			dispatch({ type: "SET_ERROR", payload: error });
		} else {
			dispatch({ type: "SET_ERROR", payload: null });
		}
	}, [error]);

	useEffect(() => {
		if (!loading) {
			dispatch({ type: "SET_FETCHED_DATA", payload: true });
		}
	}, [loading]);

	const isEmptyData =
		!costData ||
		categoriesList.every((cat) => {
			const cItem = costData[cat.key];
			return !cItem || (cItem.savings === 0 && cItem.count === 0);
		});

	const columns: ColumnsType<{
		key: string;
		label: string;
		savings: number;
		count: number;
		isLoading?: boolean;
	}> = [
		{
			title: "Category",
			dataIndex: "label",
			key: "label",
			render: (text, record) => {
				if (record.isLoading) {
					return <Skeleton active paragraph={false} title={{ width: "60%" }} />;
				}
				return text;
			},
		},
		{
			title: "Cost Savings",
			dataIndex: "savings",
			key: "savings",
			align: "center",
			render: (val, record) => {
				if (record.isLoading) {
					return <Skeleton active paragraph={false} title={{ width: "50%" }} />;
				}
				return `$${val.toLocaleString()}`;
			},
		},
		{
			title: "Count (Repairs)",
			dataIndex: "count",
			key: "count",
			align: "center",
			render: (val, record) => {
				if (record.isLoading) {
					return <Skeleton active paragraph={false} title={{ width: "30%" }} />;
				}
				return val;
			},
		},
	];

	const actualRows = categoriesList.map((cat) => {
		const cItem = costData ? costData[cat.key] : undefined;
		const savings = cItem?.savings ?? 0;
		const count = cItem?.count ?? 0;
		return {
			key: cat.key,
			label: cat.label,
			savings,
			count,
			isLoading: false,
		};
	});

	const skeletonRows = categoriesList.map((_, idx) => ({
		key: `sk-${idx}`,
		label: "",
		savings: 0,
		count: 0,
		isLoading: true,
	}));

	const dataSource = state.isLoadingData ? skeletonRows : actualRows;

	useEffect(() => {
		if (state.error) {
			message.error("Failed to load cost data.");
		}
	}, [state.error]);

	const showError = !state.isLoadingData && !!state.error;
	const showEmpty = !state.isLoadingData && state.hasFetchedData && isEmptyData;
	const showTable = !showError && !showEmpty;

	return (
		<Card
			className={`cost-savings-card ${state.isAnimated ? "slide-down" : "slide-down-hidden"}`}
			bodyStyle={{ padding: "1.5rem" }}
		>
			<div className="mb-4 w-100">
				{state.isLoadingData ? (
					<Skeleton.Input style={{ width: "100%" }} active size="large" />
				) : (
					<Select
						className="cost-savings-select w-100"
						value={activeMonth}
						onChange={onMonthChange}
						options={monthOptions.map((m) => ({ label: m, value: m }))}
					/>
				)}
			</div>

			{showError && (
				<Alert
					className="mb-4"
					message="Error"
					description={state.error}
					type="error"
					showIcon
				/>
			)}

			{showEmpty && (
				<div className="mb-4 w-100 d-flex justify-content-center align-items-center">
					<DFPEmpty
						className="w-100 h-100"
						description="No cost data for the selected month."
					/>
				</div>
			)}

			{showTable && (
				<div className="mb-4 w-100">
					<DFPTable
						className="cost-savings-table"
						columns={
							state.isLoadingData
								? columns.map((col) => ({ ...col, title: "" })) // hide headers in skeleton
								: columns
						}
						dataSource={dataSource}
						pagination={false}
						bordered
						locale={{
							emptyText: !state.isLoadingData ? (
								<DFPEmpty className="w-100 h-100" description="No data." />
							) : null,
						}}
					/>
				</div>
			)}
		</Card>
	);
};
