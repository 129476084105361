// plugin-service.ts

import { auth } from "@/assets/services/auth-service";
import { ALFRED_SERVICE_URL } from "@/constants/env";
import { DFSFieldOption } from "@/views/SignedIn/DynamicSubmissionForm";
import type { DFSFormField } from "@/views/SignedIn/SingleForm/DynamicSubmissionForm";
import axios from "axios";
import dayjs from "dayjs";

export interface DFSPluginUiPatch {
	validateStatus?: "success" | "warning" | "error" | "validating";
	help?: string;
	suffix?: React.ReactNode;
	loading?: boolean;
}

export interface DFSPluginResult {
	updatedFormData: Record<string, unknown>;
	uiPatches: Record<string, DFSPluginUiPatch>;
}

/**
 * The signature of an async plugin function.
 *
 * NOTE: We'll pass an extraParam `formSections` so we can detect
 * if the target field is a date field => parse date with dayjs.
 */
export type DFSPluginFn = (
	field: DFSFormField,
	formData: Record<string, unknown>,
	extraParams?: Record<string, unknown>,
) => Promise<DFSPluginResult>;

/**
 * Helper that checks if a given field name is typed as "date"
 * in the form config. This is a simple approach:
 */
function isDateField(fieldName: string, allFormSections: any[] = []): boolean {
	// Recursively search all sections/fields for a field with `name=fieldName && type=date`
	// This is not super optimized, but fine in smaller forms.
	for (const section of allFormSections) {
		const fields = section.fields || section.questions || [];
		for (const f of fields) {
			if (f.name === fieldName && f.type === "date") {
				return true;
			}
			// If "group" or "dynamicList", you'd recurse further, etc.
			if (f.fields?.length) {
				if (isDateField(fieldName, [{ fields: f.fields }])) return true;
			}
		}
	}
	return false;
}

/**
 * Attempts to parse dateString into a Dayjs object if it looks valid.
 * Otherwise returns the string as-is.
 */
function coerceDateValue(
	fieldName: string,
	rawValue: string,
	formSections?: any[],
): dayjs.Dayjs | null | string {
	if (!formSections) return rawValue; // If no sections, we can't do detection
	const shouldParse = isDateField(fieldName, formSections);
	if (!shouldParse) return rawValue; // It's not a date field in the config

	// Try parsing
	try {
		const parsed = dayjs(
			rawValue,
			[
				"MM/DD/YYYY",
				"YYYY-MM-DD",
				"MM-DD-YYYY",
				"MM/YYYY",
				"YYYY-MM",
				"MM-YYYY",
			],
			true,
		);
		return parsed.isValid() ? parsed : null;
	} catch (e) {
		console.error(e);
		return null;
	}
}

/**
 * "ocrImage" plugin:
 *  - Hits /FacilityVision/OCR/VelebitPlateRead
 *  - For each key in `ocr`, we see if there's a propMap => store it
 *  - If that field is typed "date", parse the string => Dayjs
 */
const ocrImagePlugin: DFSPluginFn = async (
	field,
	formData,
	extraParams = {},
) => {
	const fileUrl = extraParams.fileUrl as string | undefined;
	const formSections = extraParams.formSections as any[] | undefined;
	const dependencies = extraParams.dependencies as
		| Record<string, string>
		| undefined;
	const fieldOptions = extraParams.fieldOptions as
		| Record<string, DFSFieldOption[]>
		| undefined;

	if (!fileUrl) {
		return { updatedFormData: formData, uiPatches: {} };
	}

	// 1) Download the file
	let arrayBuffer: ArrayBuffer;
	try {
		const fileResponse = await fetch(fileUrl);
		arrayBuffer = await fileResponse.arrayBuffer();
	} catch (e) {
		return {
			updatedFormData: formData,
			uiPatches: {
				[field.name || ""]: {
					validateStatus: "error",
				},
			},
		};
	}

	// 2) Build FormData
	const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
	const formDataToSend = new FormData();
	formDataToSend.append("file", blob, "platePhoto.jpg");

	// Add options if available, similar to assetConditionPlugin
	if (dependencies?.options && fieldOptions?.[dependencies.options]) {
		const formattedOptions = fieldOptions[dependencies.options].map(
			(option) => ({
				label: option.label,
				value: option.value,
			}),
		);
		formDataToSend.append(
			"options",
			JSON.stringify({ manufacturers: formattedOptions }),
		);
	}

	formDataToSend.append("extra_fields", "");
	formDataToSend.append("use_similarity_model", "true");

	// 3) POST => /FacilityVision/OCR/VelebitPlateRead
	let respData: any;
	try {
		const token = await auth.currentUser?.getIdToken();
		const resp = await axios.post<{
			status: string;
			data: {
				ocr: Record<string, { text: string; confidence: number } | null>;
			};
		}>(
			`${ALFRED_SERVICE_URL.replace("app/", "app")}/FacilityVision/OCR/VelebitPlateRead`,
			formDataToSend,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token ?? ""}`,
				},
			},
		);
		respData = resp.data?.data;
		if (!respData || !respData.ocr) {
			throw new Error("Missing data.ocr in OCR response.");
		}
	} catch (err) {
		return {
			updatedFormData: formData,
			uiPatches: {
				[field.name || ""]: {
					validateStatus: "error",
				},
			},
		};
	}

	// 4) Merge data
	const updatedFormData = { ...formData };
	const uiPatches: Record<string, DFSPluginUiPatch> = {};

	const propMap = (field.metadata?.propMap as Record<string, string>) || {};

	for (const [ocrKey, fieldObj] of Object.entries(respData.ocr)) {
		if (!fieldObj) continue; // skip
		const { text, confidence } = fieldObj;

		const targetField = propMap[ocrKey];
		if (!targetField) continue; // skip unmapped

		// If it's a date field, parse => Dayjs
		const finalValue = coerceDateValue(targetField, text, formSections);

		updatedFormData[targetField] = finalValue;

		let validateStatus: "success" | "warning" | "error" = "success";
		if (confidence < 0.5) validateStatus = "error";
		else if (confidence < 0.8) validateStatus = "warning";

		uiPatches[targetField] = {
			validateStatus,
			suffix: (
				<span style={{ color: "#666" }}>{(confidence * 100).toFixed(1)}%</span>
			),
		};
	}

	return { updatedFormData, uiPatches };
};

/**
 * "assetCondition" plugin:
 *  - Calls /FacilityVision/Predict/AssetCondition
 *  - Returns numeric rating => store it in the mapped field if present
 *  - This typically won't be a date, but we can still do the same logic
 */
const assetConditionPlugin: DFSPluginFn = async (
	field,
	formData,
	extraParams = {},
) => {
	const fileUrl = extraParams.fileUrl as string | undefined;
	const dependencies = extraParams.dependencies as
		| Record<string, string>
		| undefined;
	const fieldOptions = extraParams.fieldOptions as
		| Record<string, DFSFieldOption[]>
		| undefined;

	if (!fileUrl) {
		return { updatedFormData: formData, uiPatches: {} };
	}

	const updatedFormData = { ...formData };
	const uiPatches: Record<string, DFSPluginUiPatch> = {};

	try {
		const imageResponse = await fetch(fileUrl);
		const arrayBuffer = await imageResponse.arrayBuffer();
		const blob = new Blob([arrayBuffer], { type: "image/jpeg" });

		const formDataToSend = new FormData();
		formDataToSend.append("file", blob, "assetImage.jpg");

		// Add condition options if available
		if (dependencies?.options && fieldOptions?.[dependencies.options]) {
			const formattedOptions = fieldOptions[dependencies.options].map(
				(option) => ({
					label: option.label,
					value: Number(option.value), // Ensure value is a number
				}),
			);
			formDataToSend.append("options", JSON.stringify(formattedOptions));
		}

		const token = await auth.currentUser?.getIdToken();
		const resp = await axios.post<{ status: string; data: number }>(
			`${ALFRED_SERVICE_URL.replace("app/", "app")}/FacilityVision/Predict/AssetCondition`,
			formDataToSend,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token ?? ""}`,
				},
			},
		);

		const conditionScore = resp.data?.data ?? 0;
		const propMap = (field.metadata?.propMap as Record<string, string>) || {};
		const targetField = propMap?.assetCondition;

		if (targetField) {
			updatedFormData[targetField] = conditionScore;
			uiPatches[targetField] = {
				validateStatus: "success",
			};
		}
	} catch (error) {
		uiPatches.assetConditionError = {
			validateStatus: "error",
		};
	}

	return { updatedFormData, uiPatches };
};

/**
 * "barcodeScanner" plugin:
 *  - Hits /BlobDecode/
 *  - user can specify "scan_type" in field.metadata (e.g. "barcode" or "qr")
 *  - If we get a result => fill it in the mapped field
 */
const barcodeScannerPlugin: DFSPluginFn = async (
	field,
	formData,
	extraParams = {},
) => {
	const fileUrl = extraParams.fileUrl as string | undefined;
	const formSections = extraParams.formSections as any[] | undefined;
	if (!fileUrl) {
		return { updatedFormData: formData, uiPatches: {} };
	}

	const updatedFormData = { ...formData };
	const uiPatches: Record<string, DFSPluginUiPatch> = {};

	try {
		// 1) Download
		const imageResponse = await fetch(fileUrl);
		const arrayBuffer = await imageResponse.arrayBuffer();

		// 2) Build form data for /BlobDecode
		const blob = new Blob([arrayBuffer], { type: "image/jpeg" });
		const formDataToSend = new FormData();
		formDataToSend.append("file", blob, "scanFile.jpg");

		// If user sets scanType => pass it. If undefined => pass nothing => server tries both
		const scanType = field.metadata?.scanType as string | undefined;
		if (scanType) {
			formDataToSend.append("scan_type", scanType);
		}

		// optional hint
		const hint = field.metadata?.hint as string;
		if (hint) {
			formDataToSend.append("hint", hint);
		}

		// 3) POST => /BlobDecode
		const token = await auth.currentUser?.getIdToken();
		const resp = await axios.post<{
			status: string;
			results: Record<string, unknown> | string;
		}>(
			`${ALFRED_SERVICE_URL.replace("app/", "app")}/BlobDecode/`,
			formDataToSend,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token ?? ""}`,
				},
			},
		);

		const scanResults = resp.data?.results;
		if (!scanResults) {
			throw new Error("No results from barcodeScanner");
		}

		// 4) Merge into form
		const propMap = (field.metadata?.propMap as Record<string, string>) || {};

		// If server returns a single string => e.g. "ABC123"
		if (typeof scanResults === "string") {
			const targetField = propMap?.decodedValue;
			if (targetField) {
				updatedFormData[targetField] = scanResults; // store string
				uiPatches[targetField] = {
					validateStatus: "success",
				};
			}
			// skip if no mapping
		} else {
			// It's an object => loop all keys
			for (const [resKey, val] of Object.entries(scanResults)) {
				const targetField = propMap[resKey];
				if (!targetField) continue;

				// If it's date => parse
				if (typeof val === "string") {
					updatedFormData[targetField] = coerceDateValue(
						targetField,
						val,
						formSections,
					);
				} else {
					updatedFormData[targetField] = val;
				}

				uiPatches[targetField] = {
					validateStatus: "success",
				};
			}
		}
	} catch (err) {
		uiPatches.barcodeScannerError = {
			validateStatus: "error",
		};
	}

	return { updatedFormData, uiPatches };
};

export const dfsPluginRegistry: Record<string, DFSPluginFn> = {
	ocrImage: ocrImagePlugin,
	assetCondition: assetConditionPlugin,
	barcodeScanner: barcodeScannerPlugin,
};
