import { Rate } from "antd";
import { useEffect, useReducer } from "react";
import type { FC } from "react";

/**
 * Define the shape of props we'll accept from the parent/antd Form:
 * - `value`: the current rating (number of stars)
 * - `onChange`: callback to notify parent of rating changes
 * - `count`: how many stars to display, default 5
 * - `disabled`: whether the rating is disabled
 */
interface RatingInputProps {
	value?: number;
	onChange?: (newValue: number) => void;
	count?: number;
	disabled?: boolean;
}

/** Our local state shape */
interface RatingState {
	rating: number;
}

/** The actions our reducer can handle */
type RatingAction = { type: "SET_RATING"; payload: number } | { type: "RESET" };

const initialState: RatingState = {
	rating: 0,
};

/** Reducer function */
function ratingReducer(state: RatingState, action: RatingAction): RatingState {
	switch (action.type) {
		case "SET_RATING":
			return { ...state, rating: action.payload };
		case "RESET":
			return { ...initialState };
		default:
			return state;
	}
}

/**
 * RatingInput: A reusable rating component that integrates with antd Form
 * by accepting `value` and `onChange` props.
 */
const RatingInput: FC<RatingInputProps> = ({
	value,
	onChange,
	count = 5,
	disabled = false,
}) => {
	const [state, dispatch] = useReducer(ratingReducer, initialState);

	// Whenever the external value changes (e.g. form reset or initial load),
	// sync it into our local reducer state:
	useEffect(() => {
		if (typeof value === "number" && value !== state.rating) {
			dispatch({ type: "SET_RATING", payload: value });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	// Handle changes from the Rate component:
	const handleRateChange = (newVal: number) => {
		dispatch({ type: "SET_RATING", payload: newVal });
		onChange?.(newVal); // Notify the parent (antd form) of the change
	};

	return (
		<Rate
			value={state.rating}
			onChange={handleRateChange}
			count={count}
			disabled={disabled}
			className="rating-input"
		/>
	);
};

export { RatingInput };
