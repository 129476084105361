import { SubmissionDetailsHeaderProps } from "@/models/DynamicSubmissions/DynamicSubmissionDetail/DyanmicSubmissionDetail.model";
import { FilePdfOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip } from "antd";
import { FC } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { GoThumbsup } from "react-icons/go";
import { MdDownload } from "react-icons/md";

export const SubmissionDetailsHeader: FC<SubmissionDetailsHeaderProps> = ({
	isQuickView,
	toggleSubmission,
	setModalIndex,
	responsesLength,
	dispatch,
	state,
	noEditMessage,
	editSubmission,
	exportToCSV,
	downloadPdf,
	handleApprove,
}) => {
	const { pdfLoading, isDeleted, canEdit, data, isApprovalModalOpen } = state;

	return (
		<div className="submission-header d-flex flex-row align-items-center justify-content-between">
			{/* Left side navigation (back arrow) */}
			{!isQuickView ? (
				toggleSubmission && (
					<Button
						type="link"
						onClick={toggleSubmission}
						icon={<LeftOutlined />}
					>
						Back to Submissions
					</Button>
				)
			) : (
				/* If quickView, use the "previous" arrow button */
				<Button
					type="link"
					icon={<LeftOutlined />}
					onClick={() => {
						dispatch({ type: "SET_LOADING", payload: true });
						if (setModalIndex) {
							setModalIndex((prev) =>
								prev === 0 ? responsesLength - 1 : prev - 1,
							);
						}
						setTimeout(() => {
							dispatch({ type: "SET_LOADING", payload: false });
						}, 500);
					}}
				/>
			)}

			{/* Right side actions (if toggleSubmission is present, that means "full view", not quickView) */}
			{toggleSubmission && (
				<div className="d-flex gap-2">
					<div className="d-flex flex-row gap-2">
						<Button onClick={exportToCSV} icon={<MdDownload />}>
							CSV
						</Button>
						<Button
							onClick={downloadPdf}
							loading={pdfLoading}
							icon={<FilePdfOutlined />}
						>
							PDF
						</Button>
					</div>
					{(!state.isApproved || data?.is_qa_flagged) && (
						<>
							<Tooltip title={noEditMessage}>
								<Button
									type="primary"
									disabled={isDeleted || !canEdit}
									onClick={() =>
										dispatch({ type: "TOGGLE_APPROVAL_MODAL", payload: true })
									}
									icon={<GoThumbsup />}
								>
									Approve
								</Button>
							</Tooltip>
							<Modal
								title="Approve This Submission?"
								open={isApprovalModalOpen}
								onOk={handleApprove}
								onCancel={() =>
									dispatch({ type: "TOGGLE_APPROVAL_MODAL", payload: false })
								}
							>
								<p>
									Are you sure you want to approve this submission? Clicking
									"Confirm" will mark it as approved.
								</p>
							</Modal>
						</>
					)}
					<Tooltip title={noEditMessage}>
						<Button
							type="primary"
							disabled={isDeleted || !canEdit}
							onClick={editSubmission}
							icon={<AiOutlineEdit />}
						>
							Edit
						</Button>
					</Tooltip>
				</div>
			)}

			{/* QuickView "Next" arrow */}
			{isQuickView && (
				<Button
					type="link"
					icon={<LeftOutlined style={{ transform: "rotate(180deg)" }} />}
					onClick={() => {
						dispatch({ type: "SET_LOADING", payload: true });
						if (setModalIndex) {
							setModalIndex((prev) =>
								prev === responsesLength - 1 ? 0 : prev + 1,
							);
						}
						setTimeout(() => {
							dispatch({ type: "SET_LOADING", payload: false });
						}, 500);
					}}
				/>
			)}
		</div>
	);
};
