// src/constants/env.ts

// Function to safely parse JSON
const parseJson = <T>(jsonString: string, variableName: string): T => {
	try {
		return JSON.parse(jsonString);
	} catch (_error) {
		throw new Error(
			`Invalid JSON format for environment variable ${variableName}`,
		);
	}
};

// Parse Firebase Config
export const FIREBASE_CONFIG: FirebaseConfig = parseJson<FirebaseConfig>(
	import.meta.env.VITE_REACT_APP_FIREBASE_CONFIG,
	"VITE_REACT_APP_FIREBASE_CONFIG",
);

// Export Environment
export const ENV: "development" | "production" | "staging" = import.meta.env
	.VITE_ENV;

// Export OpenAI Credentials
export const OPENAI_API_KEY: string = import.meta.env.VITE_OPENAI_API_KEY;
export const OPENAI_ORG: string = import.meta.env.VITE_OPENAI_ORG;

// Export Optional Variables with Default Values
export const ADDRESS_VALIDATION_API_KEY: string =
	import.meta.env.VITE_ADDRESS_VALIDATION_API_KEY || "";
export const ALFRED_SERVICE_URL: string = import.meta.env
	.VITE_ALFRED_SERVICE_URL;
export const OCR_AUTHORIZATION_TOKEN: string =
	import.meta.env.VITE_OCR_AUTHORIZATION_TOKEN || "";
export const GEOLOC_API_KEY: string = import.meta.env.VITE_GEOLOC_API_KEY || "";
export const PACKAGE_VERSION: string =
	import.meta.env.PACKAGE_VERSION || "2.1.01";
export const SENTRY_DSN: string = import.meta.env.SENTRY_DSN || "";
export const SENTRY_AUTH_TOKEN: string =
	import.meta.env.SENTRY_AUTH_TOKEN || "";
export const REACT_SCAN_ENABLED: boolean =
	import.meta.env.REACT_SCAN_ENABLED === "true" || false;
