import { getAssetsCount } from "@services/asset-service";
import { getLocationsCount } from "@services/location-service";
import { useUserStore } from "@stores/userStore";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DashboardContext = createContext(null);

const getDates = () => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();
	const beginningYear = new Date(new Date().getFullYear(), 0, 1);
	beginningYear.setHours(0, 0, 0, 1);
	const lastMonthBeginning = new Date(currentYear, currentMonth - 1, 1);
	const lastMonthEnding = new Date(currentYear, currentMonth, 0);

	return {
		currentDate: currentDate.toISOString(),
		beginningYear: beginningYear.toISOString(),
		lastMonthBeginning: lastMonthBeginning.toISOString(),
		lastMonthEnding: lastMonthEnding.toISOString(),
	};
};

function DashboardProvider({ children }: any) {
	const [summary, setSummary] = useState<any>();
	const [loading, setLoading] = useState<any>(false);
	const authInfo = useUserStore((state: any) => state.authInfo);
	const location = useLocation();
	let locId = null;
	if (location.pathname.includes("/locations")) {
		locId = location.state?.id;
	}
	const org = authInfo.org;

	useEffect(() => {
		if (!org) return;
		let isCancelled = false;
		try {
			const fetchSummaryData = async () => {
				setLoading(true);
				const dates = getDates();

				const [
					monthAssets,
					yearAssets,
					badAssets,
					allAssets,
					locationsCountInfo,
				] = await Promise.all([
					getAssetsCount({
						locationId: locId || undefined,
						startDate: dates.lastMonthBeginning,
						endDate: dates.lastMonthEnding,
					}),
					getAssetsCount({
						locationId: locId || undefined,
						startDate: dates.beginningYear,
						endDate: dates.currentDate,
					}),
					getAssetsCount({
						locationId: locId || undefined,
						condition: [1, 2],
					}),
					getAssetsCount({
						locationId: locId || undefined,
					}),
					getLocationsCount(),
				]);

				// Only set state if the component is still mounted
				if (!isCancelled) {
					setSummary({
						allAssets,
						monthAssets,
						yearAssets,
						badAssets,
						locationsCountInfo,
					});
				}
			};
			fetchSummaryData();
		} catch (error) {
			console.error("Error fetching dashboard data:", error);
		} finally {
			if (!isCancelled) {
				setLoading(false);
			}
		}

		return () => {
			isCancelled = true;
		};
	}, [org, location, locId]);

	const value = { summary, loading };
	return (
		<DashboardContext.Provider value={value as any}>
			{children}
		</DashboardContext.Provider>
	);
}

function useDashboard() {
	const context = useContext(DashboardContext);
	if (context === undefined) {
		throw new Error("useDashboard must be used within a DashboardProvider");
	}
	return context;
}

export { DashboardProvider, useDashboard };
