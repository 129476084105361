import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import type {
	Location,
	LocationTableAction,
	LocationTableState,
} from "@/models/Tables/LocationTable/location-table.model";
import { useUserStore } from "@/stores";
import { DownloadOutlined, FileAddOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space } from "antd";
import { type Key, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnConfigDropdown } from "../Admin/SubmissionsTable/ColumnConfigDropdown";
import { getTableProps } from "../Admin/SubmissionsTable/childFunctions/getTableProps";
import { ExportModal } from "../Admin/SubmissionsTable/children/ExportModal";
import { fetchData } from "./childFunctions/fetchData";
import { initializeColumns } from "./childFunctions/initializeColumns";
import { AddLocationModal } from "./children/AddLocationModal";

const { Search } = Input;

const ASCENDING = "asc";
const DESCENDING = "desc";

const initialState: LocationTableState = {
	...baseInitialState,
	sortBy: "submission_count",
	showExportModal: false,
	checkedLocations: [],
	addLocationModal: false,
};

// Reducer function
function locationTableReducer(
	state: LocationTableState,
	action: LocationTableAction,
): LocationTableState {
	switch (action.type) {
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_CHECKED_LOCATIONS":
			return { ...state, checkedLocations: action.value };
		case "SET_ADD_LOCATION_MODAL":
			return { ...state, addLocationModal: action.value };
		default:
			return baseTableReducer(state, action);
	}
}

// Submissions Table component
const LocationsTable = () => {
	const navigate = useNavigate();
	const user = useUserStore((state: Record<string, unknown>) => state.user);
	const location = useLocation();

	// Initialize state
	// Try to get preserved state from location.state or sessionStorage
	const preservedState = location.state || {};

	const [state, dispatch] = useReducer(locationTableReducer, {
		...initialState,
		pageNum: preservedState.pageNum || initialState.pageNum,
		pageSize: preservedState.pageSize || initialState.pageSize,
		searchVal: preservedState.searchVal || initialState.searchVal,
		sortBy: preservedState.sortBy || initialState.sortBy,
		sortOrder:
			(preservedState.sortOrder as typeof ASCENDING | typeof DESCENDING) ||
			initialState.sortOrder,
		columnsShown: preservedState.columnsShown || initialState.columnsShown,
	});

	const organization = user?.organization?.external_firebase_id;
	const {
		sortBy,
		sortOrder,
		pageNum,
		pageSize,
		searchVal,
		isLoading,
		showExportModal,
		checkedLocations,
		addLocationModal,
	} = state;

	useEffect(() => {
		initializeColumns(organization, dispatch);
	}, [organization]);

	useEffect(() => {
		fetchData(state, dispatch);
	}, [pageNum, pageSize, sortBy, sortOrder, searchVal, organization]);

	const navigateToLocation = (locationId: string) => {
		const tableState = {
			searchVal,
			sortBy,
			sortOrder,
			pageNum,
		};
		navigate(`/locations/${locationId}`, {
			state: tableState,
		});
	};

	const tableProps = getTableProps(state, dispatch, {
		handleRowClick: (record: Location) => {
			navigateToLocation(record.id.toString());
		},
		rowSelection: {
			onChange: (selectedRowKeys: Key[], selectedRows: Location[]) => {
				dispatch({ type: "SET_CHECKED_LOCATIONS", value: selectedRowKeys });
			},
		},
	});

	const handleToggleExportModal = () => {
		dispatch({ type: "SET_SHOW_EXPORT_MODAL", value: !showExportModal });
	};

	const handleToggleAddLocationModal = () => {
		dispatch({ type: "SET_ADD_LOCATION_MODAL", value: !addLocationModal });
	};

	return (
		<div className="table-card">
			<div className="d-flex justify-content-between">
				<Space>
					<Search
						placeholder="Search"
						allowClear
						style={{ width: 300 }}
						onSearch={(value: string) => {
							dispatch({ type: "SET_SEARCH_VAL", payload: value });
						}}
						disabled={isLoading}
						defaultValue={state?.searchVal}
					/>
				</Space>
				<Space>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<Button
						icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={handleToggleExportModal}
					/>
					<Button
						icon={<FileAddOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={handleToggleAddLocationModal}
					/>
				</Space>
			</div>
			<Divider />
			<DFPTable
				locale={{
					emptyText: state.isLoading ? null : (
						<DFPEmpty
							className="w-100 h-100"
							description="No locations found. Please create a new location, or adjust your search filters."
						/>
					),
				}}
				{...tableProps}
			/>
			<ExportModal
				organization={user.organization.external_firebase_id}
				showExport={showExportModal}
				toggle={handleToggleExportModal}
				browserLocationId={checkedLocations} // Extract the IDs from checked locations
			/>
			<AddLocationModal
				modalOpen={addLocationModal}
				toggle={handleToggleAddLocationModal}
				organization={organization}
			/>
		</div>
	);
};

export { LocationsTable };
