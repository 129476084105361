import { searchLocations } from "@/services/location-service";
import type { Dispatch } from "react";
import { LocationTableAction, LocationTableState } from "../LocationsTable";

export const fetchData = async (
	state: LocationTableState,
	dispatch: Dispatch<LocationTableAction>,
) => {
	const { pageNum, pageSize, sortBy, sortOrder, searchVal } = state;

	// Calculate the correct offset based on pageNum (1-based) and pageSize
	const offset = (pageNum - 1) * pageSize;

	console.log("fetchData", state);
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });
		const responses = await searchLocations(
			searchVal || undefined,
			offset,
			pageSize,
			sortBy,
			sortOrder,
			{},
		);
		if (responses) {
			dispatch({
				type: "SET_DATA_SOURCE",
				payload: responses.results,
			});
			dispatch({ type: "SET_COUNT", payload: responses.count });
		} else {
			throw new Error("Failed to search for assets");
		}
	} catch (exception) {
		console.error(`Failed to search for assets: ${exception}`);
		throw new Error("Failed to search for assets");
	} finally {
		dispatch({ type: "SET_IS_LOADING", payload: false });
	}
};
