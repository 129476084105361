import {
	CushmanExportRow,
	Question,
} from "@/models/DynamicSubmissions/DynamicSubmissionDetail/DyanmicSubmissionDetail.model";
import dayjs from "dayjs";

/** Helper to flatten line breaks, etc. */
export function sanitizeForCSV(value: string): string {
	return value
		.replace(/\r?\n/g, " ")
		.replace(/\n/g, " ")
		.replace(/\r/g, " ")
		.replace(/\t/g, " ")
		.trim();
}

/** Picks up to two images or returns ["N/A","N/A"] if none. */
export function extractTwoImages(arr: string[] | undefined): [string, string] {
	if (!Array.isArray(arr) || arr.length === 0) {
		return ["N/A", "N/A"];
	}
	const first = arr[0] ?? "N/A";
	const second = arr[1] ?? "N/A";
	return [first, second];
}

/** Can rename your section title if needed. Otherwise pass it through. */
export function mapSectionTitle(sectionTitle: string): string {
	return sectionTitle;
}

/**
 * For Cushman CSV: parse a group question into a single row if it has any data.
 */
export function parseGroupQuestion(
	submissionId: string | number,
	submissionDate: string,
	vendorName: string,
	locationName: string,
	isApproved: boolean,
	sectionTitle: string,
	groupLabel: string,
	groupValue: any[],
): CushmanExportRow | null {
	const subMap: Record<string, any> = {};
	for (const obj of groupValue) {
		if (obj && typeof obj === "object" && obj.name !== undefined) {
			subMap[obj.name] = obj.value;
		}
	}

	const descKey = Object.keys(subMap).find((k) =>
		k.toLowerCase().includes("description"),
	);
	const actionKey = Object.keys(subMap).find((k) =>
		k.toLowerCase().includes("action"),
	);
	const imageKey = Object.keys(subMap).find((k) =>
		k.toLowerCase().includes("image"),
	);

	const descriptionVal =
		descKey && subMap[descKey] ? String(subMap[descKey]).trim() : "";
	const actionVal =
		actionKey && subMap[actionKey] ? String(subMap[actionKey]).trim() : "";
	const imagesArr =
		imageKey && Array.isArray(subMap[imageKey]) ? subMap[imageKey] : [];

	const nothingFilled = !descriptionVal && !actionVal && imagesArr.length === 0;
	if (nothingFilled) {
		return null;
	}

	const [imageBefore, imageAfter] = extractTwoImages(imagesArr);

	return {
		Id: sanitizeForCSV(String(submissionId)),
		Date: submissionDate, // Already formatted outside
		VendorName: sanitizeForCSV(vendorName),
		Location: sanitizeForCSV(locationName),
		Approved: isApproved ? "TRUE" : "FALSE",
		Item: sanitizeForCSV(groupLabel),
		Category: sanitizeForCSV(mapSectionTitle(sectionTitle)),
		Description: sanitizeForCSV(descriptionVal) || "N/A",
		ActionTaken:
			sanitizeForCSV(actionVal) === "Action Performed"
				? "Repair Performed"
				: sanitizeForCSV(actionVal) || "N/A",
		ImageBefore: sanitizeForCSV(imageBefore),
		ImageAfter: sanitizeForCSV(imageAfter),
	};
}

/**
 * For Cushman CSV: parse a normal question that is NOT a group/dynamicList.
 */
export function parseSingleQuestion(
	submissionId: string | number,
	submissionDate: string,
	vendorName: string,
	locationName: string,
	isApproved: boolean,
	sectionTitle: string,
	questionLabel: string,
	questionValue: any,
): CushmanExportRow {
	let desc = "";
	if (typeof questionValue === "string") {
		// Attempt to parse if it's a date-ish string
		if (
			questionValue.includes("T") &&
			!Number.isNaN(Date.parse(questionValue))
		) {
			desc = dayjs(questionValue).format("MM/DD/YYYY");
		} else {
			desc = questionValue.trim();
		}
	} else if (typeof questionValue === "number") {
		desc = String(questionValue);
	}
	if (!desc) {
		desc = "N/A";
	}

	return {
		Id: sanitizeForCSV(String(submissionId)),
		Date: submissionDate, // Already formatted outside
		VendorName: sanitizeForCSV(vendorName),
		Location: sanitizeForCSV(locationName),
		Approved: isApproved ? "TRUE" : "FALSE",
		Item: sanitizeForCSV(questionLabel),
		Category: sanitizeForCSV(mapSectionTitle(sectionTitle)),
		Description: sanitizeForCSV(desc),
		ActionTaken: "N/A",
		ImageBefore: "N/A",
		ImageAfter: "N/A",
	};
}

/**
 * Main flatten function for a Cushman submission -> array of rows.
 */
export function flattenFieldsForCushmanCSV(
	rawInput: Record<string, any>,
	submissionId: string | number,
	submissionDate: string,
	vendorName: string,
	locationName: string,
	isApproved: boolean,
): CushmanExportRow[] {
	const formattedDate = dayjs(submissionDate).format("MM/DD/YYYY HH:mm:ss");
	const results: CushmanExportRow[] = [];

	const sections = rawInput?.sections || [];
	for (const section of sections) {
		const sectionTitle = section.title || "";
		const questions = section.questions || [];

		for (const q of questions) {
			if (!q || q.value === undefined || q.value === null || q.value === "") {
				continue;
			}

			if (
				(q.type === "group" || q.type === "dynamicList") &&
				Array.isArray(q.value)
			) {
				const labelField = q.fields?.find((f) => f.label) || q;
				const groupLabel = labelField.label || q.label || q.name || "";

				const row = parseGroupQuestion(
					submissionId,
					formattedDate,
					vendorName,
					locationName,
					isApproved,
					sectionTitle,
					groupLabel,
					q.value,
				);
				if (row) {
					results.push(row);
				}
			} else {
				const row = parseSingleQuestion(
					submissionId,
					formattedDate,
					vendorName,
					locationName,
					isApproved,
					sectionTitle,
					q.label || q.name || "",
					q.value,
				);
				results.push(row);
			}
		}
	}

	return results;
}

/**
 * If you need to flatten raw fields to CSV for standard exports.
 */
export function flattenFieldsForCSV(
	fields: Question[],
	sectionTitle: string,
	parentLabel = "",
): { Section: string; Question: string; Answer: string }[] {
	const results: { Section: string; Question: string; Answer: string }[] = [];

	for (const field of fields) {
		if (!field) continue;

		const label = field.label || field.name || "";
		const questionLabel = parentLabel ? `${parentLabel} > ${label}` : label;
		const val = field.value;

		if (
			val === undefined ||
			val === null ||
			val === "" ||
			(Array.isArray(val) && val.length === 0)
		) {
			continue;
		}

		if (
			(field.type === "group" || field.type === "dynamicList") &&
			Array.isArray(val) &&
			field.fields
		) {
			val.forEach((item: any, idx: number) => {
				const subFields = field.fields!.map((sf) => ({
					...sf,
					value: Array.isArray(item)
						? item.find((el: any) => el?.name === sf.name)?.value
						: item[sf.name || ""],
				}));
				results.push(
					...flattenFieldsForCSV(
						subFields,
						sectionTitle,
						val.length > 1 ? `${questionLabel} (#${idx + 1})` : questionLabel,
					),
				);
			});
			continue;
		}

		results.push({
			Section: sectionTitle,
			Question: questionLabel,
			Answer: String(val),
		});
	}

	return results;
}
