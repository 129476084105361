import type { Dispatch } from "react";
import type {
	SubmissionTableAction,
	SubmissionTableState,
} from "../SubmissionsTable";
import { searchResponses } from "../children/Search";

function makeSkeletonRows(count = 5) {
	return Array.from({ length: count }, (_, i) => ({
		id: `skeleton-${i}`,
		isLoading: true,
	}));
}

export async function fetchSubmissionTableData(
	state: SubmissionTableState,
	dispatch: Dispatch<SubmissionTableAction>,
	browserLocationId: string,
) {
	try {
		dispatch({ type: "SET_IS_LOADING", payload: true });

		const {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			filters,
			viewingDeleted,
			viewingDrafts,
		} = state;

		const tableParams = {
			searchVal,
			selectedForm,
			selectedFormMode,
			pageNum,
			pageSize,
			sortBy,
			sortOrder,
			browserLocationId,
			filters,
			viewingDeleted,
			viewingDrafts,
		};

		const responses = await searchResponses(tableParams);

		dispatch({
			type: "BATCH_UPDATE",
			payload: {
				...state,
				pageNum,
				dataSource: responses?.results || [],
				count: responses.count || 0,
				isLoading: false,
			},
		});
	} catch (exception) {
		console.error(`Failed to search for submissions: ${exception}`);
		dispatch({
			type: "BATCH_UPDATE",
			payload: {
				...state,
				isLoading: false,
			},
		});
		throw new Error("Failed to search for submissions");
	}
}
