import { selfOrganizationAssetTypes } from "@/services/organization-service";
import { auth } from "@assets/services/auth-service";
import { db } from "@assets/services/auth-service";
import { DB_FILTER, DB_ORDER, DB_ORG, DB_PATH } from "@constants/db";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
	addDoc,
	collection,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
} from "firebase/firestore";

dayjs.extend(customParseFormat);

/**
 * Converts json to b64, used for sending small amounts of json
 * @param json
 * @returns {string || null}
 */
const jsonToBase64 = (json) => {
	return btoa(encodeURIComponent(JSON.stringify(json)));
};

export const formatDateDynamic = (date) => {
	if (!date) {
		return null;
	}

	// Define common date formats
	const formats = [
		"MMMM D, YYYY [at] h:mm:ss A [UTC]Z",
		"YYYY-MM-DDTHH:mm:ssZ",
		"YYYY-MM-DDTHH:mm:ss.SSSZ",
		"MMM D, YYYY",
		"MM/DD/YYYY",
		"YYYY-MM-DD",
		"DD-MM-YYYY",
		"D MMMM YYYY",
		"D MMM YYYY",
		"YYYY/MM/DD",
		"DD/MM/YYYY",
		"MMM D, YYYY, h:mm:ss A",
		"MMM D, YYYY, h:mm A",
	];

	let parsedDate;
	for (const format of formats) {
		parsedDate = dayjs(date, format, true);
		if (parsedDate.isValid()) {
			break;
		}
	}

	if (!parsedDate.isValid()) {
		return null;
	}

	return parsedDate.format("MM/DD/YYYY");
};

// TODO: Revist post migration
export const getMasterAssetsTypes = async () => {
	try {
		const assetTypes = await getDocs(
			query(
				collection(db, DB_PATH.ASSET_TYPES),
				orderBy(DB_FILTER.NAME, DB_ORDER.ASC),
			),
		);
		return assetTypes;
	} catch (err) {
		throw new Error(err);
	}
};

export const isMasterAssetType = async (name) => {
	try {
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/master_asset_type/lookup/${name}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to get master asset type: ${name} ${errorDetails.detail}`,
				);
			});
		}

		const responsejson = await response.json();
		return responsejson !== null;
	} catch (exception) {
		throw new Error(exception);
	}
};

// TODO: Revist post migration
export const addMasterAssetTypes = async (data) => {
	try {
		const ref = await addDoc(collection(db, DB_PATH.ASSET_TYPES), data);
		const doc = await getDoc(ref);
		return doc;
	} catch (err) {
		throw new Error(err);
	}
};

// TODO: Revist post migration
export const savePotentialMasterAsset = async (data) => {
	try {
		const ref = await addDoc(
			collection(db, DB_PATH.MASTER_ASSETS_FOR_REVIEW),
			data,
		);
		const doc = await getDoc(ref);
		return doc;
	} catch (err) {
		throw new Error(err);
	}
};

// TODO: Revist post migration
export const getAssetTypeFromRef = async (assetTypeRef) => {
	try {
		const assetType = await getDoc(assetTypeRef);
		return assetType;
	} catch (err) {
		throw new Error(err);
	}
};

// TODO: Revist post migration
export const getAssetTypeCategories = async (organization, assetName) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.ASSET_TYPE_CATEGORIES,
		);

		const assetTypeCategory = assetName
			? await getDocs(query(colRef, where(DB_FILTER.NAME, "==", assetName)))
			: await getDocs(query(colRef, orderBy(DB_FILTER.NAME, DB_ORDER.ASC)));
		return assetTypeCategory;
	} catch (err) {
		throw new Error(err);
	}
};

// TODO: Revist post migration
export const getAssetTypes = async (organization, assetName) => {
	try {
		const colRef = collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.ASSET_TYPES,
		);

		const assetTypes = assetName
			? await getDocs(query(colRef, where(DB_FILTER.NAME, "==", assetName)))
			: await getDocs(query(colRef, orderBy(DB_FILTER.NAME, DB_ORDER.ASC)));
		return assetTypes;
	} catch (err) {
		throw new Error(err);
	}
};

export const getSelfOrganizationAssetTypes = async () => {
	try {
		const assetTypes = await selfOrganizationAssetTypes();
		return assetTypes;
	} catch (err) {
		throw new Error(err);
	}
};

export const getAssetTypesForForm = async (organization, setState) => {
	let assetTypes = await selfOrganizationAssetTypes(true);
	const parsed = assetTypes.reduce((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {});
	setState(parsed);
};

// TODO: Revist post migration
export const addAssetType = async (path, isAll, data) => {
	try {
		const colRef = isAll
			? collection(db, DB_PATH.ORGANIZATIONS, path, DB_PATH.ASSET_TYPES)
			: collection(db, path);
		const ref = await addDoc(colRef, data);
		const doc = await getDoc(ref);
		return doc;
	} catch (err) {
		throw new Error(err);
	}
};

// TODO: Revist post migration
export const getFormAssetTypes = async (organization, form, type) => {
	let docQuery = query(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.FORMS,
			form,
			DB_PATH.ASSET_TYPES,
		),
	);
	if (type) {
		docQuery = query(docQuery, where(DB_FILTER.NAME, "==", type));
	}
	const assetTypes = await getDocs(docQuery);
	return assetTypes;
};

// TODO: Revist post migration
export const getAssets = async (organization, locationId, type, tag, order) => {
	console.log("Inside getAssets", organization, locationId, type, tag, order);
	const params = [where(DB_FILTER.DELETED, "==", false)];
	if (locationId) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, "==", locationId));
	}
	if (type) {
		params.push(where(DB_FILTER.TYPE, "==", type));
	}
	if (tag) {
		params.push(where(DB_FILTER.OTHER_ASSET_TAG, "==", tag));
	}
	if (order) {
		params.push(orderBy(order, DB_ORDER.ASC));
	}
	const assetsSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params,
		),
	);
	return assetsSnap;
};

export const getAssetLocations = async (locationId) => {
	try {
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/location/${locationId}/asset_locations`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to get asset map positions for location: ${locationId} ${errorDetails.detail}`,
				);
			});
		}

		const responsejson = await response.json();
		return responsejson;
	} catch (exception) {
		throw new Error(exception);
	}
};

export const getAsset = async (id) => {
	try {
		const url = `${import.meta.env.VITE_ALFRED_SERVICE_URL}/asset/lookup/${id}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to get asset: ${id} ${errorDetails.detail}`);
			});
		}

		const responsejson = await response.json();
		return responsejson;
	} catch (exception) {
		throw new Error(exception);
	}
};

// TODO: Revist post migration
export const getLocationAssets = async (organization, locationid) => {
	const params = [where(DB_FILTER.DELETED, "==", false)];
	if (locationid) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, "==", locationid));
	}
	const assetsSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params,
		),
	);
	return assetsSnap;
};

// TODO: Revist post migration
export const getAssetFromParentRef = async (assetId) => {
	const docRef = doc(db, DB_PATH.ASSETS, assetId);
	const docSnap = await getDoc(docRef);
	return { path: assetId, data: docSnap.data() };
};

interface GetAssetCountParams {
	locationId?: number | null;
	startDate?: string;
	endDate?: string;
	condition?: number[];
}

export const getAssetsCount = async ({
	locationId,
	startDate,
	endDate,
	condition,
}: GetAssetCountParams = {}) => {
	try {
		const url = new URL(
			`${import.meta.env.VITE_ALFRED_SERVICE_URL}/asset/self/count`,
		);
		const params = new URLSearchParams();

		// Only add parameters if they are defined and have valid values
		if (locationId !== undefined && locationId !== null) {
			params.append("location_id", locationId.toString());
		}

		if (startDate?.trim()) {
			params.append("start_date", startDate);
		}

		if (endDate?.trim()) {
			params.append("end_date", endDate);
		}

		if (condition?.length) {
			// FastAPI expects array query params as repeated keys
			condition.forEach((value) => {
				params.append("condition", value.toString());
			});
		}

		// Append params if any exist
		const queryString = params.toString();
		if (queryString) {
			url.search = queryString;
		}

		const response = await fetch(url.toString(), {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
				"Content-Type": "application/json",
			},
		});

		if (!response.ok) {
			const errorDetails = await response.json();
			throw new Error(`Failed to get asset count: ${errorDetails.detail}`);
		}

		return response.json();
	} catch (error) {
		console.error("Error getting asset count:", error);
		throw error instanceof Error ? error : new Error("Unknown error occurred");
	}
};

// TODO: Revist post migration
export const addAsset = async (organization, data) => {
	const ref = await addDoc(
		collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
		data,
	);
	return ref;
};

// TODO: Revist post migration
export const addAssetVersionHistory = async (organization, id, data) => {
	await addDoc(
		collection(
			db,
			DB_PATH.ORGANIZATIONS,
			organization,
			DB_PATH.ASSETS,
			id,
			DB_PATH.VERSION_HISTORY,
		),
		data,
	);
};

// TODO: Revist post migration
export const updateAsset = async (organization, id, updatedAsset) =>
	setDoc(
		doc(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS, id),
		updatedAsset,
	);

// TODO: Revist post migration
export const deleteAsset = async (assetRef) => {
	const asset = await getDoc(assetRef);
	const submissionRef = asset.data().responseRef;
	await updateDoc(assetRef, { deleted: true });
	await updateDoc(submissionRef, { deleted: true });
};

export const getAssetSpecificDetails = async (asset) => {
	if (!asset) {
		return null;
	}
	const specificDetails = [];

	let conditional_inputs =
		asset.organization_asset_type.conditional_inputs?.inputs;
	if (!conditional_inputs) return null;

	for (const input of conditional_inputs) {
		const parsedInput = JSON.parse(input);

		// if input is array, not object
		if (parsedInput.length) {
			const label = parsedInput[0].label;
			const key = parsedInput[1].key;

			let specificDetail = asset[key];
			// 'true' means Not Found checkbox was checked for this input
			if (specificDetail == "true" || specificDetail === undefined) {
				continue;
			}
			specificDetails.push({ label, value: specificDetail });
		} else {
			let { label, key, type } = parsedInput;
			if (label == "Power Source?") label = "Power Source";
			if (key == "refrigerantCharge") key = "totalCharge";
			if (key == "refrigerant") key = "refType";
			const specificDetail = asset[key];
			if (specificDetail === undefined) continue;
			specificDetails.push({ label, value: specificDetail, type });
		}
	}
	return specificDetails;
};

export const searchAssets = async (
	val,
	pageNum,
	pageSize,
	sortBy,
	sortOrder,
	location,
	startDate,
	endDate,
	columnFilters,
) => {
	try {
		console.log(sortBy);
		const params = new URLSearchParams({
			limit: pageSize,
			sort: sortBy,
			sort_direction: sortOrder,
			offset: pageNum,
			search_value: val,
		});
		if (columnFilters && Object.keys(columnFilters).length > 0) {
			params.append("column_filter", jsonToBase64(columnFilters));
		}
		if (startDate) {
			params.append("start_date", startDate);
		}
		if (endDate) {
			params.append("end_date", endDate);
		}
		if (location) {
			params.append("location_id", location);
		}

		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/asset/search?${params.toString()}`;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});

		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(`Failed to search for assets: ${errorDetails.detail}`);
			});
		}

		const responsejson = await response.json();
		if (responsejson.hits) {
			return {
				results: responsejson.hits,
				count: responsejson.total_hits,
			};
		}
	} catch (exception) {
		throw new Error(exception);
	}
};

// TODO: Revist post migration
export const getAssetCountByDecommissionedStatus = async (
	organization,
	locationId,
	decommissioned,
) => {
	const params = [where(DB_FILTER.DELETED, "==", false)];
	if (locationId) {
		params.push(where(DB_FILTER.OTHER_LOCATION_ID, "==", locationId));
	}
	if (decommissioned) {
		params.push(where(DB_FILTER.TYPE, "==", decommissioned));
	}
	const assetsSnap = await getCountFromServer(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params,
		),
	);
	return assetsSnap.data().count;
};

// TODO: Revist post migration
export const addAssetFromForm = async (
	organization,
	submissionRef,
	data,
	chosenAssetType,
) => {
	// Check warranty date/term if there is warranty type
	let percentWarrantyLeft = null;
	let isWarrantyExpired = null;
	if (data.warrantyExpiration && data.warrantyTerm) {
		const today = new Date();
		const expDate = new Date(data.warrantyExpiration);
		const difference = expDate.getTime() - today.getTime();
		const days = difference / (1000 * 60 * 60 * 24);
		percentWarrantyLeft = (
			(days / (Number(data.warrantyTerm) * 30)) *
			100
		).toFixed(1);
		if (Number(percentWarrantyLeft) > 0) isWarrantyExpired = false;
		else if (Number(percentWarrantyLeft) <= 0) isWarrantyExpired = true;
	}
	// Generate asset data obj
	const asset = {
		responseRef: submissionRef,
		assetType: data.assetType || null,
		assetTypeRef: chosenAssetType.ref || chosenAssetType,
		make: data.manufacturer || null,
		model: data.modelNumber || null,
		serial: data.serialNumber || null,
		dateOfBirth: data.manufacturerDate || null,
		submittingUser: data.submittingUser,
		locationId: data.location.id,
		other: data,
		deleted: false,
		decommissioned: false,
		isWarrantyExpired,
		percentWarrantyLeft,
		avgUsefulLifeYears: null,
		avgReplCostUSD: null,
		replacementYear: null,
	};
	// Create asset
	const assetRef = await addAsset(organization, asset);
	return assetRef;
};

/**
 * Takes in form responseData and converts it into the format the backend is expecting
 * @param {object} data
 * @param {string} draftId
 * @returns {Promise<object>}
 */
const responseDataToParsedAsset = async (data, draftId, organization) => {
	//Fields not to add to the unique column
	let keysToExclude = [
		// Refrigeration details
		"refrigerant",
		"refrigerantNotAvailable",
		"refrigerantCharge",
		"refrigerantChargeUnitMeasurement",
		"refrigerantChargeNotAvailable",
		"refUsageType",

		// Electrical details
		"amps",
		"voltage",
		"wattage",
		"ampsNotAvailable",
		"voltageNotAvailable",
		"wattageNotAvailable",

		// Warranty details
		"warrantyStartDate",
		"warrantyExpirationDate",
		"warrantyPeriod",
		"warrantyProvider",

		// Photo details
		"idTagAssetImage",
		"tempAlertIdAssetImage",
		"wideAngleAssetImage",
		"equipmentAssetImage",
		"manufacturersPlateAssetImage",

		// Main asset details
		"location",
		"assetType",
		"formId",
		"manufacturerDate",
		"tempAlertId",
		"tempAlertIdNotAvailable",
		"assetGeneralNotes",
		"manufacturerNotAvailable",
		"manufacturerPlateNotAvailable",
		"manufacturerDateNotFound",
		"modelNumber",
		"modelNotAvailable",
		"serialNumber",
		"serialNotAvailable",
		"assetCondition",
		"assetTag",
		"powerSource",
		"assetLocation",
		"externalFirebaseId",
		"assetTypeCategory",

		//Others
		"beginDate",
		"manufacturer",
		"locationId",
		"assetTypeSuggestions",
		"deleted",
		"audited",
		"assetTypeRef",
	];
	const uniques = Object.keys(data)
		.filter((key) => {
			// Filter keys for all organizations except AMD
			if (organization === DB_ORG.AMD) return !keysToExclude.includes(key);
			// For AMD, include additional specified fields
			return (
				!keysToExclude.includes(key) ||
				[
					"assetSubArea",
					"propertyId",
					"assetClass",
					"assetNumber",
					"assetRank",
					"assetDescription",
					"assetComment",
					"inServiceDateNotFound",
					"inServiceDate",
				].includes(key)
			);
		})
		.reduce((acc, key) => {
			acc[key] = data[key];
			return acc;
		}, {});

	//Add refrigeration details
	let asset_refrigeration = {
		refrigerant_type: data.refrigerant ?? null,
		is_refrigerant_not_found: data.refrigerantNotAvailable,
		refrigerant_charge: data.refrigerantCharge ?? null,
		refrigerant_charge_measurement_unit: data.refrigerantChargeUnitMeasurement,
		is_charge_not_found: data.refrigerantChargeNotAvailable,
		usage: data.refUsageType,
	};

	//Add electrical details
	let asset_electrical = {
		amps: data.amps,
		voltage: data.voltage,
		wattage: data.wattage, //calculate with amps * voltage if not found
		is_amps_not_found: data.ampsNotAvailable,
		is_voltage_not_found: data.voltageNotAvailable,
		is_wattage_not_found: data.wattageNotAvailable,
	};

	//Add warranty detail
	let asset_warranty = {
		has_warranty: data.warrantyStartDate?.base ? true : null,
		is_warranty_expired: null,
		is_not_found: data.warrantyStartDate?.base ? false : null,
		start_date: data.warrantyStartDate?.base,
		expiration_date: data.warrantyExpirationDate?.base,
		type: null,
		term: data.warrantyPeriod,
		provider: data.warrantyProvider,
	};

	//Add photo details
	let asset_photos = [
		{ type: "ID_TAG", url: data.idTagAssetImage },
		{ type: "TEMP_ALERT", url: data.tempAlertIdAssetImage },
		{ type: "WIDE_ANGLE", url: data.wideAngleAssetImage },
		{ type: "EQUIPMENT", url: data.equipmentAssetImage },
		{ type: "MANUFACTURER_PLATE", url: data.manufacturersPlateAssetImage },
	].filter((photo) => photo.url !== undefined);

	let is_refrigeration = Object.values(asset_refrigeration).some(
		(value) => value !== null && value !== undefined,
	);
	let is_electrical = Object.values(asset_electrical).some(
		(value) => value !== null && value !== undefined,
	);
	let is_warranty = Object.values(asset_warranty).some(
		(value) => value !== null && value !== undefined,
	);

	//Formatting the date
	let isoDate;
	if (data?.manufacturerDate?.base) {
		const date = new Date(data?.manufacturerDate.base);
		isoDate = date.toISOString().split("T")[0];
	} else {
		isoDate = null;
	}

	let asset = {
		location_id: data.location,
		organization_asset_type_id: data.assetType.id,
		organization_asset_type_name: data.assetType.name,
		form_id: parseInt(data.formId), //auto
		submission_id: draftId, //from draft_id
		temp_alert_tag: data.tempAlertId,
		is_temp_alert_not_found: data.tempAlertIdNotAvailable,
		general_notes: data.assetGeneralNotes,
		is_manufacturer_not_found: data.manufacturerNotAvailable,
		is_manufacturer_plate_not_found: data.manufacturerPlateNotAvailable,
		manufacturer_date: isoDate,
		is_manufacturer_date_not_found: data.manufacturerDateNotFound,
		manufacturer_id: data?.manufacturer?.id ?? null, //need this for creating a model if it doesnt exist
		m_number: data.modelNumber,
		is_model_not_found: data.modelNotAvailable,
		serial_number: data.serialNumber,
		serial_number_not_found: data.serialNotAvailable,
		condition: data.assetCondition,
		asset_tag: data.assetTag,
		is_refrigeration: is_refrigeration,
		is_electrical: is_electrical,
		is_warranty: is_warranty,
		power_source: data.powerSource,
		organization_equipment_area: data.assetLocation,
		organization_equipment_sub_area:
			organization != DB_ORG.AMD ? data.assetSubArea : null,
		unique_fields: uniques,
		qa_flag: null,
		external_firebase_id: data.externalFirebaseId,
	};

	let body = {
		asset: asset,
		photos: asset_photos,
		raw_input: data,
	};
	if (is_refrigeration) {
		body.refrigeration = asset_refrigeration;
	}
	if (is_electrical) {
		body.electrical = asset_electrical;
	}
	if (is_warranty) {
		body.warranty = asset_warranty;
	}
	return body;
};

export const insertAssetFromForm = async (data, draftId, organization) => {
	try {
		let body = await responseDataToParsedAsset(data, draftId, organization);
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/asset/upload/${draftId}`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
			body: JSON.stringify(body),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				console.log(errorDetails);
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception) {
		throw new Error(exception);
	}
};

export const updateAssetFromForm = async (data, submissionId, organization) => {
	try {
		let body = await responseDataToParsedAsset(
			data,
			submissionId,
			organization,
		);
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/asset/update/${submissionId}`;
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
			body: JSON.stringify(body),
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(
					`Failed to update asset: ${errorDetails?.detail[0]?.msg || errorDetails?.detail}`,
				);
			});
		}
		return await response.json();
	} catch (exception) {
		throw new Error(`Failed to update asset: ${exception}`);
	}
};

export const getAssetEditHistory = async (asset_id) => {
	try {
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/asset/history/${asset_id}`;
		const response = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});
		if (response.status !== 200) {
			return response.json().then((errorDetails) => {
				throw new Error(errorDetails.detail);
			});
		}
		return await response.json();
	} catch (exception) {
		throw new Error(exception);
	}
};

export const backfillAssetData = async (assetRef, assetData) => {
	const token = await auth.currentUser.getIdToken();
	const SERVICE_ROUTE = import.meta.env.VITE_ALFRED_SERVICE_URL;
	const res = await fetch(`${SERVICE_ROUTE}/backfill-data`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify({ assetRef, asset: assetData }),
	});
	if (res.status !== 200) {
		console.error(`Error backfilling asset data`);
	} else {
		return await res.json();
	}
};

export const addPredSpendData = async (
	organization,
	formId,
	data,
	assetRef,
	submissionRef,
	chosenAssetType,
) => {
	let env = import.meta.env.VITE_ENV;
	// Attempt to create asset type relationship
	const response = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/createAssetTypeRelationship"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/createAssetTypeRelationship"
				: "http://localhost:5001/property-echo-4d747/us-central1/createAssetTypeRelationship",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				assetId: assetRef.id,
				assetTypeId: chosenAssetType.id,
				orgId: organization,
			}),
		},
	);
	if (response.status !== 200) {
		console.log("error establishing relationship between asset & asset type");
	}
	// Attempt to create master asset relationship
	let masterAsset;
	const response2 = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/createMasterAssetRelationship"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/createMasterAssetRelationship"
				: "http://localhost:5001/property-echo-4d747/us-central1/createMasterAssetRelationship",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				assetId: assetRef.id,
				assetTypeId: chosenAssetType.id,
				orgId: organization,
			}),
		},
	);
	if (response2.status != 200) {
		console.log("error establishing relationship between asset & master asset");
	} else {
		masterAsset = await response2.json();
	}
	//
	// get missing DOB from firebase functions
	let avgUsefulLifeYears = "";
	if (masterAsset?.avgUsefulLifeYears) {
		avgUsefulLifeYears = masterAsset.avgUsefulLifeYears;
	}
	const response3 = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/getMissingDOB"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/getMissingDOB"
				: "http://localhost:5001/property-echo-4d747/us-central1/getMissingDOB",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				assetCondition: data.assetCondition || "",
				avgUsefulLifeYears,
				orgId: organization,
				formId: formId,
				assetId: assetRef.id,
				submissionId: submissionRef.id,
			}),
		},
	);
	if (response3.status != 200) {
		console.log("error establishing getting missing DOB");
	}

	const response4 = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/addAssetReplacementYear"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/addAssetReplacementYear"
				: "http://localhost:5001/property-echo-4d747/us-central1/addAssetReplacementYear",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				orgId: organization,
				assetId: assetRef.id,
			}),
		},
	);
	if (response4.status != 200) {
		console.log("error adding replacement year");
	}

	if (masterAsset.id) {
		console.log(masterAsset);
		const response5 = await fetch(
			env === "production"
				? "https://us-central1-property-echo-4d747.cloudfunctions.net/scrapeReplCost"
				: env === "staging"
					? "https://us-central1-property-echo-staging.cloudfunctions.net/scrapeReplCost"
					: "http://localhost:5001/property-echo-4d747/us-central1/scrapeReplCost",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					assetId: masterAsset.id,
				}),
			},
		);
		if (response5.status != 200) {
			console.log("Could not scrape replacement cost");
		}
	}
};

export const addAssetHealthScore = async (organization, assetId) => {
	let env = import.meta.env.VITE_ENV;
	const response = await fetch(
		env === "production"
			? "https://us-central1-property-echo-4d747.cloudfunctions.net/getAssetHealthScore"
			: env === "staging"
				? "https://us-central1-property-echo-staging.cloudfunctions.net/getAssetHealthScore"
				: "http://localhost:5001/property-echo-4d747/us-central1/getAssetHealthScore",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				orgId: organization,
				assetId: assetId,
			}),
		},
	);
	if (response.status !== 200) {
		console.log("error getting healthscore");
	}
};

// TODO: Revist post migration
export const getAllExtractedInfo = async () => {
	try {
		const info = await getDocs(query(collection(db, "asset-specs")));
		return info;
	} catch (e) {
		console.log(e);
	}
};

export const getExtractedInfo = async (model) => {
	try {
		const url = `${
			import.meta.env.VITE_ALFRED_SERVICE_URL
		}/manual-aggregation/${model}`;
		const result = await fetch(url, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
			},
		});
		if (result.status !== 200) {
			return result.json().then((errorDetails) => {
				throw new Error(
					`Failed to get extracted info for model: ${model} ${errorDetails.detail}`,
				);
			});
		}
		const responsejson = await result.json();
		return responsejson?.results;
	} catch (e) {
		console.log(`Error getting extracted info for model: ${model}`);
	}
};

// TODO: Revist post migration
export const getPredSpendAssetCount = async (
	organization,
	years,
	locationId,
) => {
	const params = [where(DB_FILTER.DELETED, "==", false)];
	if (locationId) {
		params.push(where("locationId", "==", locationId));
	}
	if (years) {
		const startYear = new Date().getFullYear() + 1;
		const endYear = startYear + years - 1;
		params.push(where("replacementYear", ">=", startYear));
		params.push(where("replacementYear", "<=", endYear));
	}
	const assetsSnap = await getCountFromServer(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...params,
		),
	);
	return assetsSnap.data().count;
};

// TODO: Revist post migration
export const decommissionAsset = async (org, docId, decommissionReason) => {
	if (!docId) {
		throw new Error(
			"Invalid document ID provided. It must be a valid Firestore document ID.",
		);
	}
	const today = new Date();
	const date =
		today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

	const decommissionData = {
		decommissioned: true,
		decommission_date: date,
		decommission_reason: decommissionReason,
	};

	console.log("Decommissioning asset with ID:", docId);

	const decommission = updateDoc(
		doc(db, DB_PATH.ORGANIZATIONS, org, DB_PATH.ASSETS, docId),
		decommissionData,
	);

	return decommission;
};

// TODO: Revist post migration
export const getUnAuditedAssets = async (organization, location) => {
	console.log("Inside getunaudited", organization, location);

	const searchParams = [
		where(DB_FILTER.DELETED, "==", false),
		where(DB_FILTER.OTHER_LOCATION_ID, "==", location),
		where(DB_FILTER.OTHER_AUDITED, "==", false),
		where(DB_FILTER.DECOMMISSIONED, "==", false),
	];

	const responsesSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...searchParams,
		),
	);
	return responsesSnap.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
};

// TODO: Revist post migration
export const getAuditedAssets = async (organization, location) => {
	console.log("Inside getunaudited", organization, location);

	const searchParams = [
		where(DB_FILTER.DELETED, "==", false),
		where(DB_FILTER.OTHER_LOCATION_ID, "==", location),
		where(DB_FILTER.OTHER_AUDITED, "==", true),
		where(DB_FILTER.DECOMMISSIONED, "==", false),
	];

	const responsesSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...searchParams,
		),
	);
	return responsesSnap.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
};
export const getDecommissionedAssets = async (organization, location) => {
	console.log("Inside getunaudited", organization, location);

	const searchParams = [
		where(DB_FILTER.DELETED, "==", false),
		where(DB_FILTER.OTHER_LOCATION_ID, "==", location),
		// where(DB_FILTER.OTHER_AUDITED, '==', false),
		where(DB_FILTER.DECOMMISSIONED, "==", true),
	];

	const responsesSnap = await getDocs(
		query(
			collection(db, DB_PATH.ORGANIZATIONS, organization, DB_PATH.ASSETS),
			...searchParams,
		),
	);
	return responsesSnap.docs.map((doc) => ({
		id: doc.id,
		...doc.data(),
	}));
};
