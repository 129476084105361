import { Select } from "antd";
import { Dispatch, type FC } from "react";
import type {
	SubmissionTableAction,
	SubmissionTableState,
} from "./SubmissionsTable";
interface FormConfig {
	[key: string]: any;
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface FormSelectProps {
	state: SubmissionTableState;
	dispatch: Dispatch<SubmissionTableAction>;
}

const FormSelect: FC<FormSelectProps> = ({ state, dispatch }) => {
	const { formsArr, selectedForm, isLoading } = state;

	const handleChange = (value: number) => {
		dispatch({
			type: "BATCH_UPDATE",
			payload: {
				...state,
				selectedForm: value,
				pageNum: 1,
			},
		});
	};

	const options = formsArr
		.map((form: Form) => ({
			value: form.form_id,
			label: form.config.formName,
		}))
		.sort((a, b) => a.value - b.value);

	return (
		<Select
			style={{ width: 260 }}
			value={selectedForm}
			onChange={handleChange}
			options={options}
			placeholder={isLoading ? "Loading forms..." : "Select a form"}
			loading={isLoading}
			disabled={isLoading || formsArr.length === 0}
		/>
	);
};

export { FormSelect };
