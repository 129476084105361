import { AssetsTable } from "@/components/layouts/SignedIn/views/Admin/AssetsTable/children/AssetsTable";
import { ManageOCRTab } from "@/components/layouts/SignedIn/views/Admin/ManageOCRTab/ManageOCRTab";
import { OCRTemplateReviewer } from "@/components/layouts/SignedIn/views/Admin/OCRTemplateReviewer/OCRTemplateReviewer";
import { ReportsTab } from "@/components/layouts/SignedIn/views/Admin/ReportsTab/ReportsTab";
import { UsersTable } from "@/components/layouts/SignedIn/views/Admin/UsersTable/UsersTable";
import { ScrollableNavigationBar } from "@/components/pure/Navigation/dfp-scroll-navigation";
import { SubmissionDetail } from "@/components/pure/Submissions/submissions-detail";
import { DB_ORG } from "@/constants/db";
import { UserAccessLevel } from "@/stores/userStore";
import { CushmanDataUpdateTab } from "@SignedIn/views/Admin/CushmanDataUpdateTab/CushmanDataUpdateTab";
import { SubmissionsTab } from "@SignedIn/views/SingleLocation/SubmissionsTab/SubmissionsTab";
import { MonthlyAuditTab } from "@components/layouts/SignedIn/views/Admin/MonthlyAuditTab/MonthlyAuditTab";
import { SettingsTab } from "@components/layouts/SignedIn/views/Admin/SettingsTab/SettingsTab";
import { useUserStore } from "@stores";
import { MultiStepComponentOCRTool } from "@views/SignedIn/OCRTool/ocrTool";
import type { Dispatch } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

interface AdminProps {
	setToast: Dispatch<unknown>;
	showToast: { title: string; message: string; type?: string };
	userRole: Record<string, number>;
}

const Admin = ({ setToast, showToast, userRole }: AdminProps) => {
	const { userOrganization } = useUserStore();
	const location = useLocation();

	let tabNames = ["Users", "Submissions", "Assets", "Vision Viewer"];
	if (userOrganization?.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
		tabNames = ["Users", "Submissions", "Data Update", "Monthly Audit"];
	}

	if (userRole.access_level === 1000) {
		if (userOrganization?.external_firebase_id !== DB_ORG.CUSHMANWAKEFIELD) {
			tabNames = [
				...tabNames,
				"Reports",
				"OCR Template Tool",
				"Vision Trainer",
				// "Predictive Spend",
			];
		}
	}

	tabNames.push("Settings");

	if (!userRole || userRole.access_level < UserAccessLevel.ADMIN) {
		return <Navigate to="/plans" replace />;
	}

	return (
		<div>
			<ScrollableNavigationBar basePath={"/admin"} tabNames={tabNames} />
			<div className="fullSize">
				<Routes>
					<Route path="users" element={<UsersTable />} />
					<Route
						path="submissions"
						element={<SubmissionsTab organization={userOrganization} />}
					/>
					<Route
						path="submissions/:submissionId"
						element={<SubmissionDetail />}
					/>
					<Route
						path="assets"
						element={
							<AssetsTable browserLocationId={location.state?.locationId} />
						}
					/>
					<Route
						path="data-update"
						element={
							<CushmanDataUpdateTab
								setToast={setToast}
								organization={userOrganization}
							/>
						}
					/>
					<Route
						path="monthly-audit"
						element={<MonthlyAuditTab org={userOrganization} />}
					/>
					<Route
						path="reports"
						element={
							<ReportsTab
								organization={userOrganization}
								showToast={showToast}
							/>
						}
					/>
					<Route
						path="settings"
						element={<SettingsTab organization={userOrganization} />}
					/>
					<Route path="vision-viewer" element={<ManageOCRTab />} />
					{/* <Route path="predictive-spend" element={<PredictiveSpend />} /> */}
					<Route
						path="ocr-template-tool"
						element={<MultiStepComponentOCRTool />}
					/>
					<Route path="vision-trainer" element={<OCRTemplateReviewer />} />
					<Route path="*" element={<Navigate to="users" replace />} />
				</Routes>
			</div>
		</div>
	);
};

export { Admin };
