import { FolderOpenOutlined } from "@ant-design/icons";
import { Empty, type EmptyProps, theme } from "antd";

interface DFPEmptyProps extends EmptyProps {
	className?: string;
}

export const DFPEmpty: React.FC<DFPEmptyProps> = ({ className, ...props }) => {
	const { token } = theme.useToken();
	return (
		<Empty
			className={`${className} dfp-empty justify-content-center align-items-center d-flex flex-column`}
			image={
				props.image || (
					<FolderOpenOutlined
						style={
							props.imageStyle || {
								color: token.colorPrimary,
								fontSize: "5rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}
						}
					/>
				)
			}
			{...props}
		/>
	);
};
