import { DownloadOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Space, message } from "antd";
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useReducer,
	useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type {
	ASCENDING,
	DESCENDING,
} from "../AssetsTable/children/AssetsTable";

import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";

import { selfOrganizationAssetTypes } from "@/services/organization-service";

import { type StringMap } from "@/models/abstractTypes";
import { SubmissionData } from "../../SingleLocation/SubmissionsTab/children/DynamicSubmissionDetailAssetRenderer";
import { ColumnConfigDropdown } from "./ColumnConfigDropdown";
import { getOrgColumns } from "./Columns/SubmissionsColumns";
import { DeleteButton } from "./DeleteButton";
import { DeleteModal } from "./DeleteModal";
import { DeletedSwitch } from "./DeletedSwitch";
import { FormSelect } from "./FormSelect";
import { QASubmissionDraftSelect } from "./QASubmissionDraftSelect";
import { fetchSubmissionTableData } from "./childFunctions/fetchData";
import { getTableProps } from "./childFunctions/getTableProps";
import { initializeColumnsShown } from "./childFunctions/initializeColumnsShown";
import { initializeFormsAndColumns } from "./childFunctions/initializeFormsAndColumns";
import { ExportModal } from "./children/ExportModal";

const { Search } = Input;

/* ---------------------------------------------------------------------
   Types
   --------------------------------------------------------------------- */
export interface Submission {
	id: string | number;
	formIcon: ReactNode;
	asset_tag: string;
	asset_type: string;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

export interface Draft {
	id: string | number;
	address: string;
	submitting_user: string;
	submitted_date: string;
	isLoading?: boolean;
}

interface FilterOptions {
	assetTypes: string[];
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface FormConfig {
	formName: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface SubmissionsTableProps {
	organization: StringMap;
	browserLocationId: string;
}

/* ---------------------------------------------------------------------
   Reducer Types
   --------------------------------------------------------------------- */
export type SubmissionTableAction =
	| BaseTableAction
	| { type: "SET_VIEWING_DRAFTS"; payload: boolean }
	| { type: "SET_VIEWING_DELETED"; payload: boolean }
	| { type: "SET_FORMS_ARR"; payload: Form[] }
	| { type: "SET_SELECTED_FORM"; payload: number }
	| { type: "SET_SELECTED_FORM_MODE"; payload: string }
	| { type: "SET_DATA_FLEET_FORMS"; payload: unknown }
	| { type: "SET_FILTERS"; payload: Record<string, unknown> }
	| { type: "SET_SHOW_EXPORT_MODAL"; value: boolean }
	| { type: "SET_SHOW_DELETE_MODAL"; value: boolean }
	| { type: "SET_FILTER_OPTIONS"; payload: FilterOptions }
	| { type: "BATCH_UPDATE"; payload: SubmissionTableState }
	| { type: "SET_IS_VIEWING_QA_FORM"; payload: boolean }
	| { type: "SET_IS_LOADING"; payload: boolean }
	| { type: "SET_REFETCH_TRIGGER"; payload: number };

export interface SubmissionTableState extends BaseTableState {
	viewingDrafts: boolean;
	viewingDeleted: boolean;
	formsArr: Form[];
	selectedForm: number | undefined;
	selectedFormMode: string;
	filters: Record<string, unknown>;
	filterOptions: FilterOptions | null;
	showExportModal: boolean;
	showDeleteModal: boolean;
	isViewingQAForm: boolean;
	refetchTrigger: number;
	isLoading: boolean;
}

/* ---------------------------------------------------------------------
   Initial State + Reducer
   --------------------------------------------------------------------- */
const initialState: SubmissionTableState = {
	...baseInitialState,
	tableColumns: [],
	sortBy: "end_time",
	viewingDrafts: false,
	viewingDeleted: false,
	formsArr: [],
	selectedForm: undefined,
	isViewingQAForm: false,
	selectedFormMode: "0",
	filters: {},
	filterOptions: null,
	showExportModal: false,
	showDeleteModal: false,
	refetchTrigger: 0,
	isLoading: true,
};

/**
 * Merges only those fields from `newValues` which are actually present (defined).
 * Other fields in `oldState` remain untouched.
 */
function partialMerge<T extends object>(oldState: T, newValues: Partial<T>): T {
	const updated: T = { ...oldState };
	for (const [key, val] of Object.entries(newValues) as [
		keyof T,
		T[keyof T],
	][]) {
		if (val !== undefined) {
			updated[key] = val;
		}
	}
	return updated;
}

function reducer(
	state: SubmissionTableState,
	action: SubmissionTableAction,
): SubmissionTableState {
	switch (action.type) {
		case "SET_VIEWING_DRAFTS":
			if (state.viewingDrafts !== action.payload) {
				return { ...state, viewingDrafts: action.payload };
			}
			return state;
		case "SET_VIEWING_DELETED":
			if (state.viewingDeleted !== action.payload) {
				return { ...state, viewingDeleted: action.payload };
			}
			return state;
		case "SET_IS_VIEWING_QA_FORM":
			if (state.isViewingQAForm !== action.payload) {
				return { ...state, isViewingQAForm: action.payload };
			}
			return state;
		case "SET_FORMS_ARR":
			if (JSON.stringify(state.formsArr) !== JSON.stringify(action.payload)) {
				return { ...state, formsArr: action.payload };
			}
			return state;
		case "SET_SELECTED_FORM":
			if (state.selectedForm !== action.payload) {
				return { ...state, selectedForm: action.payload };
			}
			return state;
		case "SET_SELECTED_FORM_MODE":
			if (state.selectedFormMode !== action.payload) {
				return { ...state, selectedFormMode: action.payload };
			}
			return state;
		case "SET_IS_LOADING":
			if (state.isLoading !== action.payload) {
				return { ...state, isLoading: action.payload };
			}
			return state;
		case "SET_FILTERS":
			if (JSON.stringify(state.filters) !== JSON.stringify(action.payload)) {
				return { ...state, filters: action.payload };
			}
			return state;
		case "SET_FILTER_OPTIONS":
			return { ...state, filterOptions: action.payload };
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_SHOW_DELETE_MODAL":
			return { ...state, showDeleteModal: action.value };
		case "BATCH_UPDATE":
			return partialMerge(state, action.payload);
		case "SET_REFETCH_TRIGGER":
			return { ...state, refetchTrigger: action.payload };
		default:
			return baseTableReducer(state, action as BaseTableAction);
	}
}

/* ---------------------------------------------------------------------
   SubmissionsTable
   --------------------------------------------------------------------- */
const SubmissionsTable: FC<SubmissionsTableProps> = ({
	organization,
	browserLocationId,
}) => {
	const location = useLocation();
	const navigate = useNavigate();

	// Memoize preserved state
	const preservedState = useMemo(
		() => location?.state?.submissionsTableParams || {},
		[location?.state?.submissionsTableParams],
	);

	// Memoize initial state
	const initialTableState = useMemo(
		() => ({
			...initialState,
			pageNum: preservedState?.pageNum ?? initialState.pageNum,
			pageSize: preservedState?.pageSize ?? initialState.pageSize,
			searchVal: preservedState?.searchVal ?? initialState.searchVal,
			sortBy: preservedState?.sortBy ?? initialState.sortBy,
			sortOrder:
				(preservedState?.sortOrder as typeof ASCENDING | typeof DESCENDING) ??
				initialState.sortOrder,
			selectedFormMode:
				preservedState?.selectedFormMode ?? initialState.selectedFormMode,
			selectedForm: preservedState?.selectedForm ?? initialState.selectedForm,
			viewingDeleted:
				preservedState?.viewingDeleted ?? initialState.viewingDeleted,
			filters: preservedState?.filters ?? initialState.filters,
			filterOptions:
				preservedState?.filterOptions ?? initialState.filterOptions,
			tableColumns: getOrgColumns(organization, undefined),
			columnsShown: initializeColumnsShown(
				getOrgColumns(organization, undefined),
			),
			formsArr: preservedState?.formsArr ?? initialState.formsArr,
		}),
		[organization, preservedState],
	);

	const [state, dispatch] = useReducer(reducer, initialTableState);

	// Memoize row click handler
	const handleRowClick = useCallback(
		(record: SubmissionData) => {
			const isAdminView = location.pathname.includes("admin");
			const locId = browserLocationId || "";

			if (state.viewingDrafts) {
				// Draft logic
				const responseData = record?.raw_input;
				const locationObj = record?.location;
				const userLocation = record?.draft?.userLocation;
				navigate(`/forms/${record.form_id}`, {
					state: {
						responseData,
						draftId: record.id,
						formId: record.form_id,
						isSubmissionCompleted:
							record?.end_time !== null &&
							record?.end_time !== undefined &&
							record?.end_time !== "",
						locationId: locationObj.id,
						savedLocation: locationObj,
						chosenAssetType: responseData?.assetType,
						userLocation,
						isQA: false,
						fromDraftsTable: state.viewingDrafts,
						filters: state.filters,
						filterOptions: state.filterOptions,
						formsArr: state.formsArr,
					},
				});
				return;
			}

			// Normal submission
			navigate(
				isAdminView
					? `/admin/submissions/${record.id}`
					: `/locations/${locId}/submissions/${record.id}`,
				{
					state: {
						...location.state,
						formId: state.selectedForm,
						submissionsTableParams: {
							...location.state?.submissionsTableParams,
							pageNum: state.pageNum,
							pageSize: state.pageSize,
							sortBy: state.sortBy,
							sortOrder: state.sortOrder,
							searchVal: state.searchVal,
							viewingDeleted: state.viewingDeleted,
							selectedFormMode: state.selectedFormMode,
							viewingDrafts: state.viewingDrafts,
							selectedForm: state.selectedForm,
							filters: state.filters,
							filterOptions: state.filterOptions,
							formsArr: state.formsArr,
						},
					},
				},
			);
		},
		[
			browserLocationId,
			location.pathname,
			location.state,
			navigate,
			state.filters,
			state.filterOptions,
			state.formsArr,
			state.pageNum,
			state.pageSize,
			state.searchVal,
			state.selectedForm,
			state.selectedFormMode,
			state.sortBy,
			state.sortOrder,
			state.viewingDeleted,
			state.viewingDrafts,
		],
	);

	// Add this effect to handle navigation returns
	useEffect(() => {
		// Only trigger refetch if we have preserved state and required data
		if (
			preservedState &&
			state.selectedForm &&
			state.selectedFormMode &&
			state.formsArr.length &&
			!state.isLoading
		) {
			// Trigger a refetch by incrementing the refetchTrigger
			dispatch({
				type: "SET_REFETCH_TRIGGER",
				payload: state.refetchTrigger + 1,
			});
		}
	}, [preservedState]); // Only run when preservedState changes (i.e., on navigation)

	// Combine all fetch dependencies into a single memoized object
	const fetchDependencies = useMemo(
		() => ({
			selectedForm: state.selectedForm,
			selectedFormMode: state.selectedFormMode,
			viewingDrafts: state.viewingDrafts,
			viewingDeleted: state.viewingDeleted,
			sortBy: state.sortBy,
			sortOrder: state.sortOrder,
			pageNum: state.pageNum,
			pageSize: state.pageSize,
			searchVal: state.searchVal,
			filters: state.filters,
			refetchTrigger: state.refetchTrigger,
		}),
		[
			state.selectedForm,
			state.selectedFormMode,
			state.viewingDrafts,
			state.viewingDeleted,
			state.sortBy,
			state.sortOrder,
			state.pageNum,
			state.pageSize,
			state.searchVal,
			state.filters,
			state.refetchTrigger,
		],
	);

	// Modify the data fetch effect
	useEffect(() => {
		if (
			!fetchDependencies.selectedForm ||
			!fetchDependencies.selectedFormMode ||
			!state.formsArr.length
		) {
			return;
		}

		if (state.isLoading) {
			return;
		}

		const controller = new AbortController();
		let isMounted = true;

		const fetchData = async () => {
			try {
				dispatch({ type: "SET_IS_LOADING", payload: true });
				await fetchSubmissionTableData(state, dispatch, browserLocationId);
			} catch (error) {
				if (isMounted && !controller.signal.aborted) {
					console.error("Error fetching submission data:", error);
					message.error("Failed to fetch submission data");
					dispatch({ type: "SET_IS_LOADING", payload: false });
				}
			}
		};

		fetchData();

		return () => {
			isMounted = false;
			controller.abort();
		};
	}, [fetchDependencies, browserLocationId, state.formsArr.length]);

	const formsFetched = useRef(false);

	// Initial load effect
	useEffect(() => {
		let isMounted = true;

		async function initialLoad() {
			if (!isMounted || formsFetched.current) return; // Skip if forms are already fetched

			try {
				if (
					preservedState?.selectedForm &&
					preservedState?.formsArr?.length > 0
				) {
					dispatch({
						type: "BATCH_UPDATE",
						payload: {
							...preservedState,
							isLoading: false,
						},
					});
					dispatch({ type: "SET_REFETCH_TRIGGER", payload: Date.now() });
					formsFetched.current = true; // Mark forms as fetched
					return;
				}

				dispatch({ type: "SET_IS_LOADING", payload: true });

				if (state.formsArr.length === 0) {
					const assetTypes = await selfOrganizationAssetTypes(false);
					if (!isMounted) return;

					dispatch({ type: "SET_FILTER_OPTIONS", payload: { assetTypes } });

					const initialState = await initializeFormsAndColumns(
						{ ...state, filterOptions: { assetTypes } },
						dispatch,
						organization,
					);

					if (!isMounted) return;

					dispatch({
						type: "BATCH_UPDATE",
						payload: {
							...state,
							...initialState,
							selectedForm: initialState.selectedForm || state.selectedForm,
							isLoading: false,
						},
					});

					formsFetched.current = true; // Mark forms as fetched
				}
			} catch (error) {
				if (isMounted) {
					console.error("Error in initial load:", error);
					message.error("Failed to load initial data");
					dispatch({ type: "SET_IS_LOADING", payload: false });
				}
			}
		}

		initialLoad();

		return () => {
			isMounted = false;
		};
	}, [organization, preservedState]);

	// Create a memoized actual data source
	const actualDataSource = useMemo(() => {
		if (state.isLoading) {
			return Array(state.pageSize || 10).fill({ isLoading: true });
		}
		return state.dataSource;
	}, [state.isLoading, state.dataSource, state.pageSize]);

	// Update the tableProps memo
	const tableProps = useMemo(
		() =>
			getTableProps(state, dispatch, {
				handleRowClick,
				dataSource: actualDataSource,
			}),
		[actualDataSource, handleRowClick, state, dispatch],
	);

	/* ---------------------------------------------------------------------
     Modal toggles
     --------------------------------------------------------------------- */
	const toggleExportModal = useCallback(() => {
		dispatch({ type: "SET_SHOW_EXPORT_MODAL", value: !state.showExportModal });
	}, [state.showExportModal]);

	const toggleDeleteModal = useCallback(() => {
		dispatch({ type: "SET_SHOW_DELETE_MODAL", value: !state.showDeleteModal });
	}, [state.showDeleteModal]);

	/* ---------------------------------------------------------------------
     Render
     --------------------------------------------------------------------- */
	return (
		<div className="table-card">
			<div className="table-header d-flex justify-content-between">
				<Search
					placeholder="Search"
					allowClear
					style={{ width: 300 }}
					onSearch={(value: string) => {
						dispatch({ type: "SET_SEARCH_VAL", payload: value });
					}}
					disabled={state.isLoading}
					defaultValue={state.searchVal}
				/>
				<Space>
					<FormSelect state={state} dispatch={dispatch} />
					<QASubmissionDraftSelect state={state} dispatch={dispatch} />
					<DeletedSwitch state={state} dispatch={dispatch} />
				</Space>
				<Space>
					<ColumnConfigDropdown state={state} dispatch={dispatch} />
					<DeleteButton state={state} onClick={toggleDeleteModal} />
					<Button
						icon={<DownloadOutlined style={{ fontSize: "20px" }} />}
						type="text"
						onClick={toggleExportModal}
					/>
				</Space>
			</div>

			<Divider />

			<DFPTable
				locale={{
					emptyText:
						!state.isLoading && actualDataSource.length === 0 ? (
							<DFPEmpty
								className="w-100 h-100"
								style={{ minHeight: "50dvh" }}
								description={
									!state.selectedForm
										? "Please select a form to view submissions"
										: "No submissions returned. Please modify your search term or update your filters and try again."
								}
							/>
						) : null,
				}}
				{...tableProps}
			/>

			<ExportModal
				showExport={state.showExportModal}
				toggle={toggleExportModal}
				browserLocationId={browserLocationId}
				tableSelectedForm={state.selectedForm ?? null}
				tableSelectedFormMode={state.selectedFormMode}
			/>
			<DeleteModal
				state={state}
				dispatch={dispatch}
				browserLocationId={browserLocationId}
				tableType="submissions"
				fetchData={fetchSubmissionTableData}
			/>
		</div>
	);
};

export { SubmissionsTable };
