import { QAReasonCard } from "@/components/Cards/dfp-submission-qa-card";
import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import { DB_ORG } from "@/constants/db";
import type {
	DynamicSubmissionDetailAction,
	DynamicSubmissionDetailProps,
	DynamicSubmissionDetailState,
	Question,
} from "@/models/DynamicSubmissions/DynamicSubmissionDetail/DyanmicSubmissionDetail.model";
import { getSQLForm } from "@/services/form-service";
import { approveSubmission } from "@/services/submission-service";
import { useFormStore } from "@/stores/formStore";
import { normalizeStringForComparison } from "@/utils/transforms";
import { isValidUrl } from "@/utils/utilityFns";
import { getSQLSubmissionWithAsset } from "@services/submission-service";
import { useUserStore } from "@stores/userStore";
import { Card, Descriptions, Image, Spin, Typography, message } from "antd";
import dayjs from "dayjs";
import html2pdf from "html2pdf.js";
import { type FC, ReactNode, useEffect, useMemo, useReducer } from "react";
import { LuXCircle } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import {
	type SubmissionData,
	renderAssetDetails,
} from "./DynamicSubmissionDetailAssetRenderer";
import { SubmissionDetailsHeader } from "./DynamicSubmissionDetailHeader";
import {
	flattenFieldsForCSV,
	flattenFieldsForCushmanCSV,
} from "./DynamicSubmissionExports";
import { EditLog } from "./EditLog";

const { Title } = Typography;

// Our local reducer
function DynamicSubmissionDetailsReducer(
	state: DynamicSubmissionDetailState,
	action: DynamicSubmissionDetailAction,
): DynamicSubmissionDetailState {
	switch (action.type) {
		case "SET_LOADING":
			return { ...state, isLoading: action.payload };
		case "SET_STATE":
			return { ...state, ...action.payload };
		case "TOGGLE_APPROVAL_MODAL":
			return { ...state, isApprovalModalOpen: action.payload };
		default:
			return state;
	}
}

// Our initial state for the reducer
const SubmissionDetailsInitialState: DynamicSubmissionDetailState = {
	isLoading: true,
	submissionData: {},
	formName: "",
	formLogo: "",
	dateOfVisit: "",
	address: "",
	pdfLoading: false,
	locationId: "",
	isApprovalModalOpen: false,
	canEdit: false,
	isSubmissionCompleted: false,
	isApproved: false,
	isDeleted: false,
	data: {},
};

/* ======================================================================================
   Subcomponent: SubmissionContent
   Renders the "submission-content" area (logo, title, QAReasonCard, and either
   the form sections or the dynamic asset details).
   ====================================================================================== */
export interface DynamicSubmissionDetailsContentProps {
	state: DynamicSubmissionDetailState;
	userOrgId: string;
}

/**
 * Renders a single question’s value (common logic),
 * skipping any empty or invalid data.
 */
function renderDynamicSubmissionDetailsQuestionValue(
	question: Question,
	state: DynamicSubmissionDetailState,
	userOrgId: string,
): ReactNode {
	const { address } = state;

	// For standard org vs. Cushman, we call different logic
	if (userOrgId === DB_ORG.CUSHMANWAKEFIELD) {
		return renderQuestionCushmanValue(question, state, userOrgId);
	}

	const val = question?.value;
	if (
		val === undefined ||
		val === null ||
		val === "" ||
		(Array.isArray(val) && val.length === 0)
	) {
		return null;
	}

	if (
		(question.type === "group" || question.type === "dynamicList") &&
		Array.isArray(val) &&
		question.fields
	) {
		// Identify "old style" vs "new style" vs "other style"
		const firstItem = val[0];
		const isOldStyle = Array.isArray(firstItem);
		const isNewStyle =
			!isOldStyle &&
			firstItem !== null &&
			typeof firstItem === "object" &&
			!("fields" in firstItem);
		const isOtherStyle =
			!isOldStyle &&
			firstItem !== null &&
			typeof firstItem === "object" &&
			"fields" in firstItem &&
			Array.isArray(firstItem.fields);

		// ----- OLD STYLE => array-of-arrays
		if (isOldStyle) {
			const oldStyleItems: ReactNode[] = [];
			val.forEach((subArr: Record<string, string>[], idx: number) => {
				if (!Array.isArray(subArr)) return;
				const subMap: Record<string, unknown> = {};
				// biome-ignore lint/complexity/noForEach:
				subArr.forEach((el) => {
					if (el && typeof el === "object" && el.name) {
						subMap[el.name] = el.value;
					}
				});

				const nestedFields: ReactNode[] = [];
				// biome-ignore lint/complexity/noForEach:
				question.fields!.forEach((sf) => {
					const subKey = sf.name || sf.key || sf.responseTag;
					const subVal = subMap[subKey || ""];
					const childNode = renderDynamicSubmissionDetailsQuestionValue(
						{ ...sf, value: subVal },
						state,
						userOrgId,
					);
					if (
						childNode !== null &&
						!(Array.isArray(childNode) && childNode.length === 0)
					) {
						nestedFields.push(
							<Descriptions.Item
								key={`${idx}-${subKey}`}
								label={sf.label || subKey}
							>
								{childNode}
							</Descriptions.Item>,
						);
					}
				});

				if (nestedFields.length > 0) {
					oldStyleItems.push(
						<Descriptions
							key={`old-style-${idx}-${question.name || question.key}`}
							bordered
							column={1}
							labelStyle={{ fontWeight: "bold", width: "30%" }}
							className="mb-4"
						>
							{nestedFields}
						</Descriptions>,
					);
				}
			});

			if (oldStyleItems.length === 0) return null;
			return <>{oldStyleItems}</>;
		}

		// ----- NEW STYLE => array-of-plain-objects
		if (isNewStyle) {
			const newStyleItems: ReactNode[] = [];
			val.forEach((obj: Record<string, unknown>, idx: number) => {
				if (!obj || typeof obj !== "object") return;
				const nestedFields: ReactNode[] = [];
				// biome-ignore lint/complexity/noForEach:
				question.fields!.forEach((sf) => {
					const subKey = sf.name || sf.key || sf.responseTag;
					const subVal = obj[subKey as keyof typeof obj];
					const childNode = renderDynamicSubmissionDetailsQuestionValue(
						{ ...sf, value: subVal },
						state,
						userOrgId,
					);
					if (
						childNode !== null &&
						!(Array.isArray(childNode) && childNode.length === 0)
					) {
						nestedFields.push(
							<Descriptions.Item
								key={`${idx}-${subKey}`}
								label={sf.label || subKey}
							>
								{childNode}
							</Descriptions.Item>,
						);
					}
				});

				if (nestedFields.length > 0) {
					newStyleItems.push(
						<Descriptions
							key={`new-style-${idx}-${question.name || question.key}`}
							bordered
							column={1}
							labelStyle={{ fontWeight: "bold", width: "30%" }}
							className="mb-4"
						>
							{nestedFields}
						</Descriptions>,
					);
				}
			});

			if (newStyleItems.length === 0) return null;
			return <>{newStyleItems}</>;
		}

		// ----- OTHER STYLE => each item has .fields
		if (isOtherStyle) {
			const otherStyleItems: ReactNode[] = [];
			val.forEach((item: Record<string, any>, idx: number) => {
				if (!item || !Array.isArray(item.fields)) return;
				const nestedFields: ReactNode[] = [];
				// biome-ignore lint/complexity/noForEach:
				question.fields!.forEach((sf) => {
					const subObj = item.fields.find(
						(f: Record<string, string>) => f.name === sf.name,
					);
					const subVal = subObj?.value;
					const childNode = renderDynamicSubmissionDetailsQuestionValue(
						{ ...sf, value: subVal },
						state,
						userOrgId,
					);
					if (
						childNode !== null &&
						!(Array.isArray(childNode) && childNode.length === 0)
					) {
						nestedFields.push(
							<Descriptions.Item
								key={`${idx}-${sf.name}`}
								label={sf.label || sf.name}
							>
								{childNode}
							</Descriptions.Item>,
						);
					}
				});

				if (nestedFields.length > 0) {
					otherStyleItems.push(
						<Descriptions
							key={`other-style-${idx}-${question.name || question.key}`}
							bordered
							column={1}
							labelStyle={{ fontWeight: "bold", width: "30%" }}
							className="mb-4"
						>
							{nestedFields}
						</Descriptions>,
					);
				}
			});

			if (otherStyleItems.length === 0) return null;
			return <>{otherStyleItems}</>;
		}

		// if none matched, skip
		return null;
	}

	// IMAGE
	if (question.type === "image") {
		const images = Array.isArray(val) ? val : [val];
		const valid = images.filter(isValidUrl);
		if (valid.length === 0) return null;
		return valid.map((url, i) => (
			<Image
				key={`${question.name || question.key}-img-${i}`}
				src={url}
				alt={question.label || "image"}
				style={{ maxWidth: 250, marginRight: 8, marginBottom: 8 }}
				preview={{ destroyOnClose: true }}
			/>
		));
	}

	// DATE
	if (question.type === "date") {
		const dt = dayjs(val);
		const dateStr = dt.isValid() ? dt.format("MM/DD/YYYY") : String(val);
		return <CopyableCellRenderer value={dateStr} />;
	}

	// LOCATION => "location_id" case => use state's address
	if (
		question.type === "location" &&
		(question.name === "location_id" || question.key === "location_id")
	) {
		return <CopyableCellRenderer value={address} />;
	}

	// LOCATION => fallback
	if (question.type === "location") {
		return (
			<CopyableCellRenderer
				value={
					typeof question.value === "string"
						? question.value
						: JSON.stringify(val)
				}
			/>
		);
	}

	// BOOLEAN
	if (typeof val === "boolean") {
		return <CopyableCellRenderer value={val ? "Yes" : "No"} />;
	}

	// ARRAY
	if (Array.isArray(val)) {
		if (val.every((x) => typeof x === "string" || typeof x === "number")) {
			return <CopyableCellRenderer value={val.join(", ")} />;
		}
		return <CopyableCellRenderer value={JSON.stringify(val)} />;
	}

	// OBJECT => fallback to JSON
	if (typeof val === "object") {
		return <CopyableCellRenderer value={JSON.stringify(val)} />;
	}

	// If there's a format
	if (question.format) {
		let strVal = String(val);
		try {
			if (question.format.formatFn) {
				const formatFunc = eval(`(${question.format.formatFn})`);
				if (typeof formatFunc === "function") {
					strVal = formatFunc(strVal);
				}
			}
			if (question.format.inputSuffix) {
				strVal += ` ${question.format.inputSuffix}`;
			}
		} catch (err) {
			console.warn("Error applying formatFn:", err);
		}
		return <CopyableCellRenderer value={strVal} />;
	}

	// Fallback => raw string
	return <CopyableCellRenderer value={String(val)} />;
}

/**
 * Renders a single question’s value (Cushman-specific).
 */
function renderQuestionCushmanValue(
	question: Question,
	state: DynamicSubmissionDetailState,
	userOrgId: string,
): ReactNode {
	const { address } = state;

	let val = question?.value;
	if (
		val === undefined ||
		val === null ||
		val === "" ||
		(Array.isArray(val) && val.length === 0)
	) {
		return null;
	}

	// Group/dynamicList => nested
	if (
		(question.type === "group" || question.type === "dynamicList") &&
		Array.isArray(val) &&
		question.fields
	) {
		const firstItem = val[0];
		const isOldStyle = Array.isArray(firstItem);
		const isNewStyle = !isOldStyle && typeof firstItem === "object";

		if (isOldStyle) {
			const nestedItems: ReactNode[] = [];
			val.forEach((subArr: Record<string, string>[], idx: number) => {
				if (!Array.isArray(subArr)) return;
				const subMap: Record<string, unknown> = {};
				// biome-ignore lint/complexity/noForEach:
				subArr.forEach((el) => {
					if (el?.name) {
						subMap[el.name] = el.value;
					}
				});
				// biome-ignore lint/complexity/noForEach:
				question.fields!.forEach((sf) => {
					const subKey = sf.name || sf.key || sf.responseTag;
					const subVal = subMap[subKey || ""];
					const childNode = renderDynamicSubmissionDetailsQuestionValue(
						{ ...sf, value: subVal },
						state,
						userOrgId,
					);
					if (
						childNode !== null &&
						!(Array.isArray(childNode) && childNode.length === 0)
					) {
						nestedItems.push(
							<Descriptions.Item
								key={`${idx}-${subKey}`}
								label={sf.label || subKey}
							>
								{childNode}
							</Descriptions.Item>,
						);
					}
				});
			});

			if (nestedItems.length === 0) return null;
			return (
				<Descriptions
					bordered
					column={1}
					labelStyle={{ fontWeight: "bold", width: "30%" }}
				>
					{nestedItems}
				</Descriptions>
			);
		}
		if (isNewStyle) {
			// array-of-objects
			const subMap: Record<string, unknown> = {};
			// biome-ignore lint/complexity/noForEach:
			val.forEach((obj: Record<string, string>) => {
				if (obj?.name) {
					subMap[obj.name] = obj.value;
				}
			});
			const nestedItems: ReactNode[] = [];
			// biome-ignore lint/complexity/noForEach:
			question.fields!.forEach((sf) => {
				const subKey = sf.name || sf.key || sf.responseTag;
				const subVal = subMap[subKey || ""];
				const childNode = renderDynamicSubmissionDetailsQuestionValue(
					{ ...sf, value: subVal },
					state,
					userOrgId,
				);
				if (
					childNode !== null &&
					!(Array.isArray(childNode) && childNode.length === 0)
				) {
					nestedItems.push(
						<Descriptions.Item key={subKey} label={sf.label || subKey}>
							{childNode}
						</Descriptions.Item>,
					);
				}
			});

			if (nestedItems.length === 0) return null;
			return (
				<Descriptions
					bordered
					column={1}
					labelStyle={{ fontWeight: "bold", width: "30%" }}
				>
					{nestedItems}
				</Descriptions>
			);
		}
		return null;
	}

	// IMAGE
	if (question.type === "image") {
		const images = Array.isArray(val) ? val : [val];
		const valid = images.filter(isValidUrl);
		if (valid.length === 0) return null;
		return valid.map((url, i) => (
			<Image
				key={`${question.name || question.key}-img-${i}`}
				src={url}
				alt={question.label || "image"}
				style={{ maxWidth: 250, marginRight: 8 }}
				preview={{
					destroyOnClose: true,
					closeIcon: (
						<span className="p-2 bg-white">
							<LuXCircle size="1.25rem" />
						</span>
					),
				}}
			/>
		));
	}

	// DATE
	if (question.type === "date") {
		const dt = dayjs(val);
		const str = dt.isValid() ? dt.format("MM/DD/YYYY") : String(val);
		return <CopyableCellRenderer value={str} />;
	}

	// LOCATION => "location" means display the address
	if (
		question.type === "location" &&
		(question.name === "location" || question.key === "location")
	) {
		return <CopyableCellRenderer value={address} />;
	}

	// fallback location
	if (question.type === "location") {
		return (
			<CopyableCellRenderer
				value={
					typeof question.value === "string"
						? question.value
						: JSON.stringify(val)
				}
			/>
		);
	}

	// BOOLEAN
	if (typeof val === "boolean") {
		return <CopyableCellRenderer value={val ? "Yes" : "No"} />;
	}

	// ARRAY => join if simple
	if (
		Array.isArray(val) &&
		val.every((v) => typeof v === "string" || typeof v === "number")
	) {
		return <CopyableCellRenderer value={val.join(", ")} />;
	}

	// OBJECT => JSON
	if (typeof val === "object") {
		return <CopyableCellRenderer value={JSON.stringify(val)} />;
	}

	// If there's a format
	if (question.format) {
		let valStr = String(val);
		try {
			if (question.format.formatFn) {
				const fFn = eval(`(${question.format.formatFn})`);
				if (typeof fFn === "function") {
					valStr = fFn(valStr);
				}
			}
			if (question.format.inputSuffix) {
				valStr = `${valStr} ${question.format.inputSuffix}`;
			}
		} catch (e) {
			console.warn("Cannot parse or run formatFn:", e);
		}
		return <CopyableCellRenderer value={valStr} />;
	}

	// fallback => string
	return <CopyableCellRenderer value={String(val)} />;
}

/** Renders each section + question from the state's submissionData. */
function RenderDynamicSubmissionDetailSections({
	submissionData,
	userOrgId,
	state,
}: any) {
	const sections =
		submissionData.sections ||
		(Array.isArray(submissionData) && submissionData[0]?.sections) ||
		[];

	const items: ReactNode[] = [];
	const renderedKeys = new Set<string>();

	sections.forEach((section: any, sIndex: number) => {
		if (!section) return;
		const fields = section.questions || section.fields || [];
		const sectionHasValue = fields.some((q: any) => {
			if (!q) return false;
			const v = q.value;
			if (!v) return false;
			if (Array.isArray(v) && v.length === 0) return false;
			return true;
		});
		if (!sectionHasValue) return; // skip entire section if no data

		// Section title row
		items.push(
			<Descriptions.Item key={`section-${sIndex}-${section.title}`} span={3}>
				<Title level={4}>{section.title}</Title>
			</Descriptions.Item>,
		);

		fields.forEach((q: Question, qIndex: number) => {
			if (!q) return;
			const qKey = q.key || q.responseTag || q.name || `${sIndex}-${qIndex}`;
			if (renderedKeys.has(qKey)) {
				console.warn("Duplicate question key:", qKey);
				return;
			}
			renderedKeys.add(qKey);

			const contentNode = renderDynamicSubmissionDetailsQuestionValue(
				q,
				state,
				userOrgId,
			);
			if (
				contentNode === null ||
				(Array.isArray(contentNode) && contentNode.length === 0)
			) {
				return; // skip if empty
			}

			items.push(
				<Descriptions.Item
					key={qKey}
					label={
						q.label || q.fields?.[0]?.label || q.name || `Question #${qIndex}`
					}
					span={1}
				>
					{contentNode}
				</Descriptions.Item>,
			);
		});
	});

	if (!items.length) return null;

	return (
		<Descriptions
			bordered
			column={1}
			labelStyle={{ fontWeight: "bold", width: "30%" }}
		>
			{items}
		</Descriptions>
	);
}

/**
 * The main "SubmissionContent" component. Checks if form_id = 3 + has .asset => show asset details,
 * else show the typical sections-based rendering. Also includes QAReasonCard + EditLog if relevant.
 */
const DynamicSubmissionDetailsContent: FC<
	DynamicSubmissionDetailsContentProps
> = ({ state, userOrgId }) => {
	const { formLogo, formName, submissionData, data } = state;

	return (
		<div id="submission-content" className="justify-content-center">
			{/* Optional Logo */}
			{formLogo && (
				<div style={{ textAlign: "center", marginBottom: 20 }} className="mt-4">
					<img
						src={formLogo}
						alt="Form Logo"
						style={{ maxWidth: 200, maxHeight: 200 }}
					/>
				</div>
			)}

			{/* Form Title */}
			<div className="d-flex flex-row align-items-center justify-content-between w-100">
				<Title className="w-100 justify-content-center text-center" level={2}>
					{formName}
				</Title>
			</div>

			{/* QA Reason Card if flagged */}
			{data?.is_qa_flagged && data?.qa_flags?.length > 0 && (
				<QAReasonCard qaFlags={data.qa_flags} />
			)}

			{/* If form_id != 3 or no .asset => normal raw_input-based sections */}
			{data?.form_id !== 3 || !data?.asset ? (
				<RenderDynamicSubmissionDetailSections
					submissionData={submissionData}
					userOrgId={userOrgId}
					state={state}
				/>
			) : (
				// If it's form_id=3 + has .asset => show DB asset details
				<>{renderAssetDetails(data)}</>
			)}

			{/* EditLog if any */}
			{data?.edit_history?.length > 0 && (
				<div className="mt-4 submission-edit-log">
					<EditLog editLog={data?.edit_history} />
				</div>
			)}
		</div>
	);
};

/* ======================================================================================
   Main Component: DynamicSubmissionDetail
   Uses the local reducer, fetches data, and composes subcomponents.
   ====================================================================================== */
export const DynamicSubmissionDetail: FC<DynamicSubmissionDetailProps> = (
	props,
) => {
	const {
		submissionId,
		formId,
		toggleSubmission,
		quickView = false,
		setModalIndex,
		responsesLength = 0,
	} = props;

	// Store, location, navigate
	const { user } = useUserStore();
	const { setIsEditingRaisingCanes } = useFormStore();
	const location = useLocation();
	const navigate = useNavigate();

	// Our local state via reducer
	const [state, dispatch] = useReducer(
		DynamicSubmissionDetailsReducer,
		SubmissionDetailsInitialState,
	);

	const {
		isLoading,
		submissionData,
		formName,
		dateOfVisit,
		address,
		locationId,
	} = state;

	// Fetch on mount
	useEffect(() => {
		const fetchSubmission = async () => {
			dispatch({ type: "SET_LOADING", payload: true });

			try {
				// 1) Grab submission from DB
				const submission = await getSQLSubmissionWithAsset(submissionId, true);
				const form_data = await getSQLForm(submission.form.id);
				const locId = submission.location_id;
				let rawInput: unknown = submission.raw_input;

				if (Array.isArray(rawInput) && rawInput.length > 0) {
					rawInput = rawInput[0];
				}

				let parsedData: SubmissionData = {};
				try {
					parsedData =
						typeof rawInput === "string"
							? JSON.parse(rawInput)
							: (rawInput as SubmissionData) || {};
				} catch (e) {
					console.warn("Could not parse submission raw_input as JSON:", e);
				}

				const fName = submission.form.name;
				const canEdit = submission.can_edit;
				const isApproved = submission.is_approved;
				const isDeleted = submission.is_deleted;
				const endTime = submission.end_time;
				const isCompleted =
					endTime !== null && endTime !== undefined && endTime !== "";
				const fLogo = form_data.config?.logo || "";

				// Build address
				let addr = "";
				if (submission.location?.address1) {
					addr += submission.location.address1;
				}
				if (submission.location?.address2) {
					addr += ` ${submission.location.address2}`;
				}
				if (submission.location?.city) {
					addr += `, ${submission.location.city}`;
				}
				if (submission.location?.state) {
					addr += `, ${submission.location.state}`;
				}
				if (submission.location?.zip) {
					addr += ` ${submission.location.zip}`;
				}
				addr = addr.trim();

				// Attempt to find dateOfVisit
				let dOfVisit: string | null = null;
				const sections = parsedData?.sections || [];
				for (const section of sections) {
					const qArr = section.questions || section.fields || [];
					for (const q of qArr) {
						if (
							q?.key === "dateOf_visit" ||
							q?.key === "dateOfVisit" ||
							q?.responseTag === "dateOfVisit" ||
							q?.name === "date_of_visit" ||
							q?.name === "dateOfVisit" ||
							normalizeStringForComparison(q?.label || "") ===
								normalizeStringForComparison("Date of Visit")
						) {
							dOfVisit = q.value;
							break;
						}
					}
					if (dOfVisit) break;
				}
				if (!dOfVisit) {
					dOfVisit = submission.end_time || submission.begin_time;
				}
				const dayjsDate = dayjs(dOfVisit);
				const dateValue = dayjsDate.isValid()
					? dayjsDate.format("YYYY-MM-DD")
					: (dOfVisit ?? "").toString();

				dispatch({
					type: "SET_STATE",
					payload: {
						submissionData: parsedData,
						data: submission,
						formName: fName,
						formLogo: fLogo,
						address: addr,
						dateOfVisit: dateValue,
						locationId: locId,
						isLoading: false,
						canEdit,
						isApproved,
						isDeleted,
						isSubmissionCompleted: isCompleted,
					},
				});
			} catch (error) {
				console.error("Error fetching submission data:", error);
				message.error("Failed to load submission data.");
				dispatch({ type: "SET_LOADING", payload: false });
			}
		};

		fetchSubmission();
	}, [submissionId]);

	// Compute if user can edit
	const canUserEdit = (): boolean => {
		return user.role.access_level >= 1000 || state.canEdit;
	};

	// For tooltip if not editable
	const noEditMessage = useMemo(() => {
		if (state?.isDeleted) {
			return "This submission has been deleted.";
		}
		if (!state.canEdit) {
			if (user.role.access_level >= 1000) {
				return "You may only make changes to submissions within 9 weeks of submission.";
			}
			return "You may only edit if you created this within the first 72 hours.";
		}
		return "";
	}, [user.role.access_level, state?.canEdit, state?.isDeleted]);

	// Edit submission
	const editSubmission = () => {
		if (!canUserEdit()) return;
		setIsEditingRaisingCanes(true);
		navigate(`/forms/${formId}`, {
			state: {
				...state,
				isEditing: true,
				isSubmissionCompleted: state.isSubmissionCompleted,
				inputs: submissionData,
				submissionId,
				dateOfVisit,
				address,
				path: location.pathname,
				locationId,
				formId,
			},
		});
	};

	// Download PDF
	const downloadPdf = () => {
		dispatch({ type: "SET_STATE", payload: { pdfLoading: true } });
		try {
			const element = document.getElementById("submission-content");
			if (!element)
				throw new Error("Could not find #submission-content element.");

			const images = element.getElementsByTagName("img");
			const loadPromises = Array.from(images).map(
				(img) =>
					new Promise<void>((resolve, reject) => {
						if (img.complete) {
							resolve();
						} else {
							img.onload = () => resolve();
							img.onerror = () => reject();
						}
					}),
			);

			Promise.all(loadPromises)
				.then(() => {
					const opt = {
						margin: [0.75, 0.25, 0.75, 0.25],
						filename: `${formName || "Submission"} - ${address || ""}.pdf`,
						image: { type: "jpeg", quality: 1 },
						html2canvas: {
							scale: 1,
							useCORS: true,
							ignoreElements: (elem: HTMLElement) => {
								const className = elem.getAttribute("class") || "";
								if (className.includes("submission-edit-log")) return true;
								if (className.includes("ant-typography-copy")) return true;
								return false;
							},
						},
						jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
						pagebreak: { mode: ["avoid-all", "css"] },
					};

					html2pdf()
						.from(element)
						.set(opt)
						.save()
						.then(() =>
							dispatch({ type: "SET_STATE", payload: { pdfLoading: false } }),
						);
				})
				.catch((error) => {
					console.error("Error loading images for PDF:", error);
					message.error("Failed to load images for PDF.");
					dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
				});
		} catch (error) {
			console.error("Error generating PDF:", error);
			message.error("Failed to generate PDF.");
			dispatch({ type: "SET_STATE", payload: { pdfLoading: false } });
		}
	};

	// Export to CSV
	const exportToCSV = () => {
		try {
			const userState = useUserStore.getState().user;
			const isCushman =
				userState?.organization?.external_firebase_id ===
				DB_ORG.CUSHMANWAKEFIELD;
			let csvData = [];

			if (isCushman && state.submissionData) {
				csvData = flattenFieldsForCushmanCSV(
					state.submissionData,
					state.data?.id || "",
					state.data?.submitted_date || "",
					state.data?.submitting_user || "N/A",
					state.data?.location?.address1 || "N/A",
					state.data?.is_approved || false,
				);
			} else {
				const sections =
					state.submissionData.sections ||
					(Array.isArray(state.submissionData) &&
						state.submissionData[0]?.sections) ||
					[];

				for (const sec of sections) {
					const fields = sec.questions || sec.fields || [];
					csvData.push(...flattenFieldsForCSV(fields, sec.title || ""));
				}
			}

			if (csvData.length > 0) {
				const headers = Object.keys(csvData[0]);
				const csvRows = [headers.join(",")];

				for (const row of csvData) {
					const vals = headers.map((h) => {
						const escaped = String(row[h as keyof typeof row]).replace(
							/"/g,
							'""',
						);
						return `"${escaped}"`;
					});
					csvRows.push(vals.join(","));
				}

				const blob = new Blob([csvRows.join("\n")], {
					type: "text/csv;charset=utf-8;",
				});
				const link = document.createElement("a");
				const url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute(
					"download",
					`${formName || "Submission"} - ${address || "Details"}.csv`,
				);
				link.style.visibility = "hidden";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (err) {
			console.error("Error exporting CSV:", err);
			message.error("Failed to export to CSV.");
		}
	};

	// Approve
	const handleApprove = async () => {
		dispatch({ type: "TOGGLE_APPROVAL_MODAL", payload: false });
		const isAsset = !!state.submissionData.asset;
		try {
			await approveSubmission(submissionId, { isAsset }).then(() => {
				message.success("Submission approved successfully.");
				navigate(`/locations/${locationId}/submissions/`, {
					state: {
						...location.state,
					},
				});
			});
		} catch (error) {
			console.error("Error approving submission:", error);
			message.error("Failed to approve submission.");
		}
	};

	// If loading
	if (isLoading) {
		return (
			<div className="h-100 d-flex flex-column justify-content-center align-items-center">
				<Spin tip="Loading submission data..." size="large" />
			</div>
		);
	}

	return (
		<div className="h-100">
			<Card className="p-3 submission-wrapper" id="submission">
				<SubmissionDetailsHeader
					isQuickView={quickView}
					toggleSubmission={toggleSubmission}
					setModalIndex={setModalIndex}
					responsesLength={responsesLength}
					dispatch={dispatch}
					state={state}
					formId={formId}
					submissionId={submissionId}
					noEditMessage={noEditMessage}
					canUserEdit={canUserEdit}
					editSubmission={editSubmission}
					exportToCSV={exportToCSV}
					downloadPdf={downloadPdf}
					handleApprove={handleApprove}
				/>
				<DynamicSubmissionDetailsContent
					state={state}
					userOrgId={user.organization.external_firebase_id || ""}
				/>
			</Card>
		</div>
	);
};
