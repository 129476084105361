import { DB_ORG } from "@/constants/db";
import { useUserStore } from "@/stores";
import { normalizeStringForComparison } from "@/utils/transforms";
import { Select } from "antd";
import { type Dispatch, type FC } from "react";
import type {
	SubmissionTableAction,
	SubmissionTableState,
} from "./SubmissionsTable";

interface QASubmissionDraftSelectProps {
	state: SubmissionTableState;
	dispatch: Dispatch<SubmissionTableAction>;
}

const QASubmissionDraftSelect: FC<QASubmissionDraftSelectProps> = (
	props: QASubmissionDraftSelectProps,
) => {
	const { isLoading, selectedFormMode } = props.state;
	const { dispatch } = props;
	const user = useUserStore((state: Record<string, unknown>) => state.user);
	const organization = user?.organization?.external_firebase_id;

	const handleChange = (value: string) => {
		dispatch({
			type: "BATCH_UPDATE",
			payload: {
				...props.state,
				pageNum: 1,
				selectedFormMode: value,
				isViewingQAForm: value === "1",
				viewingDrafts: value === "2",
			},
		});
	};

	let options = [
		{ value: "0", label: "Submissions" },
		{ value: "1", label: "QA Submissions" },
		{ value: "2", label: "Drafts" },
	];

	// remove qa submisions option if not cushman wakefield
	if (
		normalizeStringForComparison(organization) ===
		normalizeStringForComparison(DB_ORG.CUSHMANWAKEFIELD)
	) {
		options = options.filter((option) => option.label !== "QA Submissions");
	}

	return (
		<Select
			style={{ width: 200 }}
			onChange={handleChange}
			defaultValue={
				selectedFormMode
					? options.find((form) => form.value === selectedFormMode)?.value
					: options[0]?.value
			}
			options={options}
			placeholder={isLoading ? "Loading Forms..." : "Select a form"}
			disabled={isLoading}
		/>
	);
};

export { QASubmissionDraftSelect };
