export function debounce<F extends (...args: any[]) => void>(
	func: F,
	time = 5000,
): ((...args: Parameters<F>) => void) & { cancel: () => void } {
	let timer: ReturnType<typeof setTimeout> | null = null;

	const debounced = function (
		this: ThisParameterType<F>,
		...args: Parameters<F>
	) {
		if (timer) clearTimeout(timer);
		timer = setTimeout(() => {
			timer = null;
			func.apply(this, args);
		}, time);
	};

	debounced.cancel = () => {
		if (timer) {
			clearTimeout(timer);
			timer = null;
		}
	};

	return debounced;
}
