import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
	Button,
	Card,
	DatePicker,
	Skeleton,
	Space,
	Switch,
	Tag,
	Tooltip,
	message,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import dayjs, { type Dayjs } from "dayjs";
import { useEffect, useReducer, useState } from "react";
import { MdDownload } from "react-icons/md";

import { DFPEmpty } from "@/components/Empty/dfp-empty";
import { CopyableCellRenderer } from "@/components/Tables/Renderers/copyable-cell-renderer";
import { DFPTable } from "@/components/Tables/dfp-table";
import {
	type BaseTableAction,
	type BaseTableState,
	baseInitialState,
	baseTableReducer,
} from "@/components/Tables/sharedTableState";
import { CSVExporter } from "@/components/pure/CSVExporter/csv-exporter";
import { getSelfForms } from "@/services/form-service";
import { getOrganizationLocations } from "@/services/location-service";
import { searchSubmissions } from "@/services/submission-service";
import { ASCENDING, DESCENDING } from "../AssetsTable/children/AssetsTable";
import { FormSelect } from "../SubmissionsTable/FormSelect";
import { initializeColumnsShown } from "../SubmissionsTable/childFunctions/initializeColumnsShown";

const { RangePicker } = DatePicker;

interface Location {
	id: number;
	name?: string;
	sitename?: string;
	address?: string;
	address1?: string;
	address2?: string;
	city?: string;
	state?: string;
	zip?: string;
	isLoading?: boolean; // For skeleton usage
}

interface SubmissionResult {
	id: number;
	location?: {
		id: number;
		address1?: string;
		address2?: string;
		city?: string;
		state?: string;
		zip?: string;
		sitename?: string;
	};
}

interface FormConfig {
	formName: string;
}

interface MasterFormKey {
	description: string | null;
	id: number;
	handler_function_key: string | null;
	name: string;
}

interface Form {
	form_id: number;
	config: FormConfig;
	required_access_level: number;
	organization_id: number;
	collections: unknown | null;
	enabled: boolean;
	master_form_key: MasterFormKey;
}

interface State extends BaseTableState {
	isFocused: boolean;
	formsArr: Form[];
	selectedForm: number | null;
	selectedFormMode: string;
	viewingDeleted: boolean;
	viewingDrafts: boolean;
	allLocations: Location[];
	locsWithSubmission: Location[];
	locsWithoutSubmission: Location[];
	isLoadingData: boolean;
	showExportModal: boolean;
	totalCount: number;
	filters: Record<string, unknown>;
}

type Action =
	| BaseTableAction
	| { type: "SET_IS_FOCUSED"; payload: boolean }
	| { type: "SET_FORMS"; payload: Form[] }
	| { type: "SET_SELECTED_FORM"; payload: number | null }
	| { type: "SET_SELECTED_FORM_MODE"; payload: string }
	| { type: "SET_VIEWING_DELETED"; payload: boolean }
	| { type: "SET_VIEWING_DRAFTS"; payload: boolean }
	| { type: "SET_ALL_LOCATIONS"; payload: Location[] }
	| { type: "SET_LOCS_WITH_SUBMISSION"; payload: Location[] }
	| { type: "SET_LOCS_WITHOUT_SUBMISSION"; payload: Location[] }
	| { type: "SET_IS_LOADING_DATA"; payload: boolean }
	| { type: "SET_SHOW_EXPORT_MODAL"; value: boolean }
	| { type: "SET_TOTAL_COUNT"; payload: number };

const initialState: State = {
	...baseInitialState,
	isFocused: true,
	formsArr: [],
	selectedForm: null,
	selectedFormMode: "0",
	viewingDeleted: false,
	viewingDrafts: false,
	allLocations: [],
	locsWithSubmission: [],
	locsWithoutSubmission: [],
	isLoadingData: true,
	showExportModal: false,
	tableColumns: [],
	columnsShown: {},
	sortBy: "end_time",
	totalCount: 0,
	filters: {},
};

const baseColumns: ColumnsType<Location> = [
	{
		key: "sitename",
		title: "Site Name",
		dataIndex: "name",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "80%" }} />;
			}
			return <CopyableCellRenderer value={record.name || "N/A"} />;
		},
	},
	{
		key: "address",
		title: "Address",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "60%" }} />;
			}
			const addressParts: string[] = [];
			if (record.address1) addressParts.push(record.address1);
			if (record.address2) addressParts.push(record.address2);
			return <CopyableCellRenderer value={addressParts.join(", ") || "N/A"} />;
		},
	},
	{
		key: "city",
		title: "City",
		dataIndex: "city",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "40%" }} />;
			}
			return <CopyableCellRenderer value={record.city || "N/A"} />;
		},
	},
	{
		key: "state",
		title: "State",
		dataIndex: "state",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "30%" }} />;
			}
			return <CopyableCellRenderer value={record.state || "N/A"} />;
		},
	},
	{
		key: "zip",
		title: "Zip",
		dataIndex: "zip",
		render: (_, record) => {
			if (record.isLoading) {
				return <Skeleton active paragraph={false} title={{ width: "20%" }} />;
			}
			return <CopyableCellRenderer value={record.zip || "N/A"} />;
		},
	},
];

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "SET_IS_FOCUSED":
			return { ...state, isFocused: action.payload };
		case "SET_FORMS":
			return { ...state, formsArr: action.payload };
		case "SET_SELECTED_FORM":
			return { ...state, selectedForm: action.payload };
		case "SET_SELECTED_FORM_MODE":
			return { ...state, selectedFormMode: action.payload };
		case "SET_VIEWING_DELETED":
			return { ...state, viewingDeleted: action.payload };
		case "SET_VIEWING_DRAFTS":
			return { ...state, viewingDrafts: action.payload };
		case "SET_ALL_LOCATIONS":
			return { ...state, allLocations: action.payload };
		case "SET_LOCS_WITH_SUBMISSION":
			return { ...state, locsWithSubmission: action.payload };
		case "SET_LOCS_WITHOUT_SUBMISSION":
			return { ...state, locsWithoutSubmission: action.payload };
		case "SET_IS_LOADING_DATA":
			return { ...state, isLoadingData: action.payload };
		case "SET_SHOW_EXPORT_MODAL":
			return { ...state, showExportModal: action.value };
		case "SET_TOTAL_COUNT":
			return { ...state, totalCount: action.payload };
		default:
			return baseTableReducer(state, action);
	}
}

interface PrevMonthTabProps {
	isFocused: boolean;
	organization: string;
}

const defaultLastMonthRange = (): [Dayjs, Dayjs] => {
	const now = dayjs();
	const start = now.subtract(1, "month").startOf("month");
	const end = now.subtract(1, "month").endOf("month");
	return [start, end];
};

export const MonthlyAuditTab: React.FC<PrevMonthTabProps> = ({
	isFocused,
	organization,
}) => {
	const [state, dispatch] = useReducer(reducer, {
		...initialState,
		isFocused,
	});

	// Default date range to last month
	const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(
		defaultLastMonthRange(),
	);
	// Toggle for with/without submission
	const [showWithSubmission, setShowWithSubmission] = useState(true);
	// State to trigger CSV export
	const [exportTriggered, setExportTriggered] = useState(false);
	// Track if we've fetched submissions at least once
	const [hasFetchedSubmissions, setHasFetchedSubmissions] = useState(false);

	const {
		selectedForm,
		selectedFormMode,
		viewingDeleted,
		viewingDrafts,
		locsWithSubmission,
		locsWithoutSubmission,
		isLoadingData,
		allLocations,
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		filters,
		totalCount,
		formsArr,
	} = state;

	useEffect(() => {
		const fetchInitialData = async () => {
			dispatch({ type: "SET_IS_LOADING_DATA", payload: true });
			try {
				const [formsResponse, locationsResponse] = await Promise.all([
					getSelfForms(),
					getOrganizationLocations(),
				]);

				dispatch({ type: "SET_FORMS", payload: formsResponse });
				dispatch({ type: "SET_ALL_LOCATIONS", payload: locationsResponse });

				if (formsResponse.length > 0) {
					dispatch({
						type: "SET_SELECTED_FORM",
						payload: formsResponse[0].form_id,
					});
				} else {
					// No forms found
					dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
				}
			} catch (error) {
				console.error("Failed to fetch initial data:", error);
				message.error("Failed to load forms or locations.");
				dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
			}
		};
		fetchInitialData();
	}, []);

	useEffect(() => {
		const fetchPrevMonthSubmissions = async () => {
			// If we have not selected a form or no locations yet, just return
			if (!selectedForm || allLocations.length === 0 || !dateRange) {
				// If we have forms and locations but no selected form, no need to set loading false prematurely.
				if (selectedForm)
					dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
				return;
			}

			dispatch({ type: "SET_IS_LOADING_DATA", payload: true });

			try {
				const [start, end] = dateRange;
				const startStr = start.format("YYYY-MM-DD");
				const endStr = end.format("YYYY-MM-DD");
				const dateRangeStr = `${startStr},${endStr}`;
				const includeDeletedParam = viewingDeleted ? "true" : "false";

				const { results, total } = await searchSubmissions(
					selectedForm,
					selectedFormMode === "1" ? 1 : 0,
					(pageNum - 1) * pageSize,
					pageSize,
					sortBy,
					sortOrder === ASCENDING ? ASCENDING : DESCENDING,
					filters || {},
					"",
					{},
					[],
					includeDeletedParam,
					dateRangeStr,
					false,
				);

				dispatch({ type: "SET_TOTAL_COUNT", payload: total || 0 });

				const submissionLocationIds = new Set(
					results
						.map((res: SubmissionResult) => res.location?.id)
						.filter(Boolean),
				);

				const withSubmission: Location[] = [];
				const withoutSubmission: Location[] = [];

				for (const loc of allLocations) {
					if (submissionLocationIds.has(loc.id)) {
						withSubmission.push(loc);
					} else {
						withoutSubmission.push(loc);
					}
				}

				dispatch({ type: "SET_LOCS_WITH_SUBMISSION", payload: withSubmission });
				dispatch({
					type: "SET_LOCS_WITHOUT_SUBMISSION",
					payload: withoutSubmission,
				});
			} catch (error) {
				console.error("Failed to fetch submissions:", error);
				message.error("Failed to load submissions for selected date range.");
			} finally {
				dispatch({ type: "SET_IS_LOADING_DATA", payload: false });
				setHasFetchedSubmissions(true);
			}
		};

		fetchPrevMonthSubmissions();
	}, [
		selectedForm,
		selectedFormMode,
		viewingDeleted,
		viewingDrafts,
		allLocations,
		pageNum,
		pageSize,
		sortBy,
		sortOrder,
		filters,
		dateRange,
	]);

	const loading = isLoadingData;
	const columnsShown = initializeColumnsShown(baseColumns);
	const columnsToRender = columnsShown
		? baseColumns.filter((col) => columnsShown[col.key])
		: baseColumns;

	const handleTableChange = (pagination: TablePaginationConfig) => {
		const { current, pageSize } = pagination;
		if (current) {
			dispatch({ type: "SET_PAGE_NUM", payload: current });
		}
		if (pageSize) {
			dispatch({ type: "SET_PAGE_SIZE", payload: pageSize });
		}
	};

	const dataToShow = showWithSubmission
		? locsWithSubmission
		: locsWithoutSubmission;
	const formName =
		formsArr.find((f) => f.form_id === selectedForm)?.config.formName ||
		"PM Report";
	const exportFileName = showWithSubmission
		? `Locations With ${formName}`
		: `Locations Without ${formName}`;

	// Generate skeleton placeholder data when loading
	const adjustedData = loading
		? Array.from({ length: state.pageSize }, () => ({ isLoading: true }))
		: dataToShow;

	const triggerExport = () => {
		setExportTriggered(true);
	};

	const showEmptyState =
		!loading && hasFetchedSubmissions && dataToShow.length === 0;

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				gap: "20px",
			}}
		>
			<Card
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
				title={
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Space align="center">
							<Tag color={showWithSubmission ? "purple-inverse" : "purple"}>
								{showWithSubmission ? "Locations With" : "Locations Without"}
							</Tag>
							<FormSelect
								state={{ ...state, isLoading: loading }}
								dispatch={dispatch}
							/>
							<Tooltip
								title={`Select date range to check ${formName} submissions for location`}
							>
								<RangePicker
									value={dateRange}
									format={"MM/DD/YYYY"}
									onChange={(dates) => {
										if (dates && dates.length === 2) {
											setDateRange([dates[0], dates[1]]);
										} else {
											// If cleared, reset to default last month
											setDateRange(defaultLastMonthRange());
										}
									}}
								/>
							</Tooltip>
							<Tooltip
								title={
									showWithSubmission
										? `Currently showing locations WITH ${formName}. Click to switch.`
										: `Currently showing locations WITHOUT ${formName}. Click to switch.`
								}
							>
								<Switch
									checked={showWithSubmission}
									onChange={(checked) => setShowWithSubmission(checked)}
									checkedChildren={
										<CheckCircleOutlined style={{ color: "green" }} />
									}
									unCheckedChildren={
										<CloseCircleOutlined style={{ color: "red" }} />
									}
								/>
							</Tooltip>
						</Space>
					</div>
				}
				extra={
					<Button
						type="default"
						disabled={loading || dataToShow.length === 0}
						onClick={triggerExport}
						icon={<MdDownload className="larger-font-icon larger-font" />}
					/>
				}
				bodyStyle={{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					overflow: "hidden",
				}}
			>
				{showEmptyState ? (
					<DFPEmpty
						className="w-100 h-100"
						description={
							showWithSubmission
								? `No locations with ${formName}.`
								: `No locations without ${formName}.`
						}
					/>
				) : (
					<div style={{ flex: 1, overflow: "auto" }}>
						<DFPTable<Location>
							columns={columnsToRender}
							dataSource={adjustedData}
							rowKey="id"
							locale={{
								emptyText: loading ? null : (
									<DFPEmpty
										className="w-100 h-100"
										description="No submissions returned."
									/>
								),
							}}
							loading={false} // relying on skeleton rows instead
							pagination={{
								current: state.pageNum,
								pageSize: state.pageSize,
								total: adjustedData.length,
								showSizeChanger: true,
							}}
							onChange={handleTableChange}
							style={{ width: "100%", height: "100%" }}
						/>
					</div>
				)}
			</Card>

			{exportTriggered && (
				<CSVExporter
					data={dataToShow}
					filename={exportFileName}
					fields={["name", "address1", "city", "state", "zip"]}
					delimiter=","
					nullReplacement="N/A"
					organization={organization}
					formId={selectedForm?.toString()}
					onComplete={() => {
						message.success("Export complete.");
						setExportTriggered(false); // Reset trigger after download
					}}
				/>
			)}
		</div>
	);
};
