import { getAssetsCount } from "@services/asset-service";
import { getLocationsCount } from "@services/location-service";
import { useUserStore } from "@stores/userStore";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const getDates = () => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();
	const beginningYear = new Date(new Date().getFullYear(), 0, 1);
	beginningYear.setHours(0, 0, 0, 1);
	const lastMonthBeginning = new Date(currentYear, currentMonth - 1, 1);
	const lastMonthEnding = new Date(currentYear, currentMonth, 0);

	return {
		currentDate: currentDate.toISOString(),
		beginningYear: beginningYear.toISOString(),
		lastMonthBeginning: lastMonthBeginning.toISOString(),
		lastMonthEnding: lastMonthEnding.toISOString(),
	};
};

function useDashboardSummary() {
	const [summary, setSummary] = useState("");
	const [loading, setLoading] = useState(true);
	const authInfo = useUserStore((state: any) => state.authInfo);
	const org = authInfo.org;
	const location = useLocation();

	const formatSummary = useCallback(
		({
			allAssets,
			monthAssets,
			yearAssets,
			badAssets,
			locationsCount,
			isLocationPage,
		}: any) => {
			const assetsSummary = `There are ${allAssets.toLocaleString()} assets in total. `;
			const monthlyAdditions = monthAssets
				? `${monthAssets.toLocaleString()} ${
						monthAssets === 1 ? "was" : "were"
					} added last month. `
				: "No new assets were added last month. ";
			const yearlyAdditions = `${yearAssets.toLocaleString()} ${
				yearAssets === 1 ? "has" : "have"
			} been added year-to-date.`;
			const conditionSummary = badAssets
				? `There ${
						badAssets === 1 ? "is" : "are"
					} ${badAssets.toLocaleString()} ${
						badAssets === 1 ? "asset" : "assets"
					} in broken or poor condition. `
				: "All assets are in good condition. ";
			const locationSummary =
				locationsCount && !isLocationPage
					? `The organization has reported ${locationsCount.total.toLocaleString()} locations, with ${locationsCount.no_submissions.toLocaleString()} having no submitted assets.`
					: "";

			return `${assetsSummary}${monthlyAdditions}${yearlyAdditions} ${conditionSummary}${locationSummary}`;
		},
		[],
	);

	useEffect(() => {
		let isCancelled = false;
		const fetchSummaryData = async () => {
			if (!org) return;

			setLoading(true);
			const dates = getDates();
			const locId = location.pathname.includes("/locations")
				? location.state?.id
				: null;
			try {
				const [monthAssets, yearAssets, badAssets, allAssets, locationsCount] =
					await Promise.all([
						getAssetsCount({
							locationId: locId,
							startDate: dates.lastMonthBeginning,
							endDate: dates.lastMonthEnding,
						}),
						getAssetsCount({
							locationId: locId,
							startDate: dates.beginningYear,
							endDate: dates.currentDate,
						}),
						getAssetsCount({ locationId: locId, condition: [1, 2] }),
						getAssetsCount({ locationId: locId }),
						getLocationsCount(),
					]);

				if (!isCancelled) {
					const formattedSummary = formatSummary({
						allAssets,
						monthAssets,
						yearAssets,
						badAssets,
						locationsCount,
						isLocationPage: !!locId,
					});
					setSummary(formattedSummary);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		};

		fetchSummaryData();
		return () => {
			isCancelled = true;
		};
	}, [org, location.pathname, location.state?.id, formatSummary]);
	return { summary, loading };
}

export { useDashboardSummary };
