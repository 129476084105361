import { LocationsTable } from "@/components/layouts/SignedIn/views/Locations/LocationsTable";
import { SubmissionDetail } from "@/components/pure/Submissions/submissions-detail";
import { DB_ORG } from "@/constants/db";
import {
	useGetSelfAllowedOrganizations,
	useGetSelfOrganization,
} from "@/hooks/organizationQueries";
import { useSelfUpdateUser } from "@/hooks/userQueries";
import { useUserStore } from "@/stores";
import { usePageStore } from "@/stores";
import { FormHandler } from "@/views/SignedIn/Forms/FormHandler";
import { PredictiveSpendNew } from "@/views/SignedIn/PredictiveSpend/PredictiveSpendNew";
import { Navbar } from "@SignedIn/Navbar/Navbar";
import { Sidebar } from "@SignedIn/Sidebar/Sidebar";
import { Toast } from "@SignedIn/Toast/Toast";
import { Copyright } from "@components/layouts/SignedIn/Copyright/Copyright";
import { Loader } from "@components/layouts/SignedIn/Loader/Loader";
import { ChatbotHistoryProvider } from "@contexts/chatbotContext";
import { DashboardProvider } from "@contexts/dashboardContext";
import { GraphProvider } from "@contexts/graphContext";
import { Admin } from "@views/SignedIn/Admin/Admin";
import { AssetProfile } from "@views/SignedIn/AssetProfile/AssetProfile";
import { CWDashboard } from "@views/SignedIn/CWDashboard/CWDashboard";
import { CheckUps } from "@views/SignedIn/CheckUps/CheckUps";
import { Dashboard } from "@views/SignedIn/Dashboard/Dashboard";
import { Forms } from "@views/SignedIn/Forms/Forms";
import { Plans } from "@views/SignedIn/Plans/Plans";
import { Profile } from "@views/SignedIn/Profile/Profile";
import { Releases } from "@views/SignedIn/Releases/Releases";
import { SingleLocation } from "@views/SignedIn/SingleLocation/SingleLocation";
import { message } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { PEChatbot } from "../../components/layouts/SignedIn/Chatbot/PEChatbot";

const SignedIn = () => {
	const { authInfo, setAuthInfo, user } = useUserStore();
	const { isSidebarOpen, setIsSidebarOpen } = usePageStore() as any;
	const [isToastShowing, setIsToastShowing] = useState<any>(true);
	const [userRole, setUserRoleState] = useState<any>([]);
	const [dashboard, setDashboard] = useState<any>();
	const [toggleChatbot, setToggleChatbot] = useState<any>(false);
	const [canViewAdmin, setCanViewAdmin] = useState<any>(false);
	const [formPage, setFormPage] = useState<any>();
	const navigate = useNavigate();
	const routeObj = useParams();
	const currentURL = routeObj["*"];
	const updateUser = useSelfUpdateUser() as any;
	const {
		userAllowedOrganizations,
		error: userAllowedOrganizationsError,
		isLoading: isLoadingUserAllowedOrganizations,
	} = useGetSelfAllowedOrganizations();
	const {
		userOrganization,
		error: userOrganizationError,
		isLoading: isLoadingUserOrganization,
	} = useGetSelfOrganization();

	const getData = async () => {
		//allowed_users_update

		// Skipping if user is not set
		if (!user || isLoadingUserAllowedOrganizations !== false) return;
		if (user.organization.external_firebase_id === DB_ORG.CUSHMANWAKEFIELD) {
			setDashboard(<CWDashboard />);
			// setFormPage(<SingleFormCushman />);
			setFormPage(<FormHandler user={user} />);
		} else {
			setDashboard(<Dashboard setDisplayToast={setIsToastShowing} />);
			setFormPage(<FormHandler user={user} />);
		}

		setCanViewAdmin(user.role.access_level >= 500);
		setUserRoleState(user.role);
	};

	const updateUserOrg = async (orgId: any, _orgName: any) => {
		if (user.organization.external_firebase_id === orgId) return;
		updateUser.mutate({
			organization_firebase_id: orgId,
			savedFilters: null,
		});

		const newAuthInfo = await JSON.parse(JSON.stringify(authInfo));
		newAuthInfo.org = orgId;
		setAuthInfo(newAuthInfo);
		message.success("Organization has been changed");
		// redirect user to locations table if routed to a single location
		if (currentURL?.includes("locations")) {
			navigate("/locations");
		}
		if (currentURL?.includes("forms")) {
			navigate("/forms");
		}
	};

	useEffect(() => {
		getData();
	}, [user, userAllowedOrganizations]);

	const showToast = (title: any, message: any, type = "success") => {
		setIsToastShowing({
			showing: true,
			title: title,
			message: message,
			type: type,
			position: "bottom",
		});
		setTimeout(() => {
			setIsToastShowing({
				showing: false,
			});
		}, 2500);
	};

	return !user ||
		!userOrganization ||
		isLoadingUserAllowedOrganizations !== false ||
		isLoadingUserOrganization !== false ? (
		<Loader />
	) : (
		<div className="page m-0 vw-100">
			<Row className="m-0 vw-100">
				<Container fluid className="p-0 m-0 vw-100">
					<div className="d-flex flex-column flex-md-row">
						<Sidebar
							isSidebarOpen={isSidebarOpen}
							className="sidebar-menu"
							name={user.name}
							orgOptions={userAllowedOrganizations}
							org={user.organization.external_firebase_id}
							updateUserOrg={updateUserOrg}
							userRole={userRole}
							canViewAdmin={canViewAdmin}
							userOrgData={userOrganization}
						/>
						<GraphProvider>
							<ChatbotHistoryProvider>
								<PEChatbot
									toggleChatbot={toggleChatbot}
									setToggleChatbot={setToggleChatbot}
								/>
								<Col
									className={
										isSidebarOpen
											? "px-4 pt-1 main-page-container min-vh-100"
											: "px-4 pt-1 main-page-container min-vh-100 main-page-close"
									}
								>
									<Navbar
										setIsToastShowing={setIsToastShowing}
										toggleChatbot={toggleChatbot}
										setToggleChatbot={setToggleChatbot}
										isSidebarOpen={isSidebarOpen}
										setIsSidebarOpen={setIsSidebarOpen}
									/>

									<DashboardProvider>
										<Routes>
											<Route path="/" element={dashboard || <Loader />} />
											<Route path="/profile" element={<Profile />} />
											{/* Dynamic Route for Integrations */}
											{/* <Route
												path='integration/:integrationType'
												element={
													<IntegrationLayout
														userOrgData={
															userOrganization
														}
													/>
												}
											>
												<Route
													index
													element={
														<Navigate to='work-order' />
													}
												/>
												<Route
													path='invoice'
													element={
														<IntegrationDataTable />
													}
												/>
												<Route
													path='work-order'
													element={
														<IntegrationDataTable />
													}
												/>
											</Route> */}
											<Route
												path="/admin/*"
												element={
													<Admin
														setToast={setIsToastShowing}
														showToast={showToast}
														userRole={userRole}
													/>
												}
											/>
											<Route
												path="/admin/assets/:assetid"
												element={<AssetProfile />}
											/>
											<Route path="/plans" element={<Plans />} />
											<Route path="/locations/*" element={<LocationsTable />} />
											<Route
												path={"/locations/:id/*"}
												element={
													<SingleLocation
														userRole={userRole}
														setToast={setIsToastShowing}
													/>
												}
											/>
											<Route
												path={"/locations/:id/assets/:assetid/"}
												element={<AssetProfile />}
											/>
											<Route
												path={"/locations/:id/submissions/:submissionId/"}
												element={<SubmissionDetail />}
											/>
											<Route path="/forms" element={<Forms />} />
											<Route path="/forms/:id/:org" element={formPage} />
											<Route path="/forms/:id/" element={formPage} />
											<Route path="/releases" element={<Releases />} />
											<Route
												path="/predictive-spend"
												element={<PredictiveSpendNew />}
											/>
											<Route path="/checkups" element={<CheckUps />} />
										</Routes>
									</DashboardProvider>

									<div className="copyright d-flex mt-5">
										<Copyright setIsToastShowing={setIsToastShowing} />
									</div>
								</Col>
							</ChatbotHistoryProvider>
						</GraphProvider>
					</div>
					<Toast
						isShowing={isToastShowing.showing}
						title={isToastShowing.title}
						subtitle={isToastShowing.message}
						position="bottom"
						type={isToastShowing.type}
					></Toast>
				</Container>
			</Row>
		</div>
	);
};

export { SignedIn };
