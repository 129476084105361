import { type StringMap } from "@/models/abstractTypes";
import { getSelfForms } from "@/services/form-service";
import { Dispatch } from "react";
import { getOrgColumns } from "../Columns/SubmissionsColumns";
import type {
	SubmissionTableAction,
	SubmissionTableState,
} from "../SubmissionsTable";
import { initializeColumnsShown } from "./initializeColumnsShown";

export async function initializeFormsAndColumns(
	state: SubmissionTableState,
	dispatch: Dispatch<SubmissionTableAction>,
	organization: StringMap,
) {
	try {
		const forms = await getSelfForms();

		const defaultForm =
			forms.length > 0
				? forms.find(
						(form: { master_form_key: { name: string } }) =>
							form.master_form_key.name === "Inventory & Tagging Form",
					) || forms[0]
				: null;

		const columns = getOrgColumns(organization, {
			...state,
			selectedForm: state.selectedForm || defaultForm?.form_id,
		});

		return {
			formsArr: forms,
			selectedForm: state.selectedForm || defaultForm?.form_id,
			tableColumns: columns,
			columnsShown: initializeColumnsShown(columns),
		};
	} catch (error) {
		console.error("Failed to initialize forms and columns:", error);
		throw error;
	}
}
